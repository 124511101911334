import React, {useState} from "react";
import {Button, Image, Row, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import {ReactComponent as RoleLogo} from "../../images/icon/icon-role.svg";
import {getDateCustomFormat} from "../../ui-util/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {ADMIN_REDUCER} from "../../reducers/admin.reducer";
import userDummyImage from "../../images/user-dummy-image/user_dummy_image@3x.png"

const AdminListComponent = () => {
    const dispatch = useDispatch();
    const {adminList, selectedRowKeys, loading} = useSelector(state => state.adminReducer)
    const [columns] = useState([
        {
            key: 'image', dataIndex: 'image', title: '', align: 'center',
            render: (image) => <Image src={!!image ? image : userDummyImage} style={{width: !!image ? 100 : 70}}/>
        }, {
            key: 'name', dataIndex: 'name', title: 'Nama',
            render: (name) => <div style={{color: Colors.darkSlateGray}}>{name}</div>
        }, {
            key: 'email', dataIndex: 'email', title: 'Email',
            render: (email) => <div style={{color: Colors.armyGray}}>{email}</div>
        }, {
            key: 'phone', dataIndex: 'phone', title: 'No. Hp',
            render: (phoneNumber) => <div style={{color: Colors.darkGrey}}>{phoneNumber}</div>
        }, {
            key: 'role', dataIndex: 'role', title: 'Role',
            render: (role) => <Row style={{color: Colors.darkGrey, alignItems: 'center'}}>
                <RoleLogo/>
                <div style={{marginLeft: 10}}>{role?.name}</div>
            </Row>
        }, {
            key: 'createdDate', dataIndex: 'created_at', title: 'Created Date',
            render: (created_at) => <div
                style={{color: Colors.darkGrey}}>{getDateCustomFormat(created_at, 'DD MMM YYYY HH:mm')}</div>
        }, {
            key: 'action', title: 'Action',
            render: (record) => (
                <Button onClick={() => {
                    window.location.href = `/admin/admin/update/${record.id}`
                }} style={{
                    borderWidth: 0,
                    color: Colors.armyGray,
                    backgroundColor: 'transparent'
                }}>Edit</Button>
            )
        }
    ])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            dispatch({type: ADMIN_REDUCER, payload: {selectedRowKeys}});
        }
    }

    return (
        <Table
            columns={columns}
            dataSource={adminList}
            rowKey="id"
            rowSelection={rowSelection}
            loading={loading}
        />
    )
}

export default AdminListComponent
