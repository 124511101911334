import React, {useEffect, useState} from "react";
import {Button, Form, Input, Select} from "antd";
import {Colors} from "../../ui-util/colors";
import {Styles} from "../../ui-util/styles";
import {useParams} from "react-router-dom";
import {createUserApi, getUserDetailApi, updateUserApi} from "../../api/user.api";
import {useSelector} from "react-redux";
import {convertPhoneNumber} from "../../ui-util/general-variable";

const {Option} = Select;

const UserForm = (props) => {
    const {id} = useParams();
    const formRef = React.createRef();
    const {slug} = props
    const {adminDetail} = useSelector(state => state.adminReducer)
    const [userFormList] = useState([
        {
            key: 'first_name',
            label: 'Nama Depan',
            placeholder: 'Masukkan nama depan',
            message: 'Masukkan nama user',
            inputType: 'input'
        }, {
            key: 'last_name',
            label: 'Nama Belakang',
            placeholder: 'Masukkan nama belakang',
            message: 'Masukkan nama user',
            inputType: 'input'
        }, {
            key: 'email',
            label: 'Email',
            placeholder: 'Masukkan email',
            message: 'Masukkan email yang valid !',
            type: 'email',
            inputType: 'input'
        }, {
            key: 'phone_number',
            label: 'Nomor HP',
            placeholder: 'Masukkan nomor hp',
            message: 'Masukkan nomor hp !',
            inputType: 'input'
        }, {
            key: 'point',
            label: 'Jumlah Poin',
            placeholder: 'Masukkan jumlah poin',
            message: 'Masukkan jumlah poin !',
            inputType: 'input'
        }, {
            key: 'gender',
            label: 'Jenis Kelamin',
            placeholder: 'Masukkan jenis kelamin',
            message: 'Masukkan jenis kelamin !',
            inputType: 'select'
        }
    ])

    useEffect(() => {
        if (!!id) {
            getUserDetailApi({user_id: id})
                .then(response => {
                    response.User.point = Number(response.point)
                    formRef.current.setFieldsValue(response.User)
                })
                .catch(err => console.log(err.response))

        }

    }, [])

    const onFinish = (values) => {
        if(slug === 'create'){
            createUser(values)
        } else {
            updateUser(values)
        }
    }

    const createUser = (values) => {
        values.point = Number(values.point)
        values.phone_number = convertPhoneNumber(values.phone_number)

        createUserApi(values)
            .then(response => {
                alert('User berhasil dibuat !')
                window.location.href = '/user/list'
            })
            .catch(err => {
                console.log('Error createUserApi', err.response)
                alert(err.response.data.detail)
            })
    }

    const updateUser = (values) => {
        updateUserApi(values, id)
            .then(response => {
                alert('Update user berhasil !')
                window.location.href = `/user/detail/${id}`
            })
            .catch(err => console.log(err.response))
    }

    return (
        <Form
            ref={formRef}
            layout="vertical"
            onFinish={onFinish}
        >
            {
                userFormList.map((item) => {
                    switch (item.inputType) {
                        case 'select':
                            return (
                                <Form.Item
                                    key={item.key}
                                    label={<div
                                        style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                                    name={item.key}
                                    rules={[
                                        {
                                            required: true,
                                            message: item.message,
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Select an option"
                                        allowClear
                                        style={{width: 450}}
                                    >
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                    </Select>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    key={item.key}
                                    label={<div
                                        style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                                    name={item.key}
                                    rules={item.key === 'email' ?
                                        [{
                                            required: true,
                                            message: item.message,
                                        }, {
                                            type: item.type,
                                            message: item.message,
                                        }] :
                                        [{
                                            required: true,
                                            message: item.message,
                                        }]
                                    }
                                >
                                    <Input disabled={!!id && item.key === 'point'} placeholder={item.placeholder}
                                           style={{width: 450, height: 40, borderRadius: 12}}/>
                                </Form.Item>
                            )
                    }
                })
            }
            {
                ((adminDetail?.capability_slugs?.includes('create-user') &&
                    slug === 'create') ||
                    (adminDetail?.capability_slugs?.includes('update-user') &&
                        slug === 'edit')) &&
                <Form.Item>
                    <Button type="primary" htmlType="submit"
                            style={{
                                ...Styles.buttonWithRadius,
                                borderColor: Colors.armyGray,
                                height: 40,
                                marginTop: 20
                            }}>
                        {slug === 'edit' ? 'Save' : 'Create User'}
                    </Button>
                </Form.Item>}
        </Form>
    )
}

export default UserForm
