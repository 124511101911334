import React, {useEffect} from "react";
import HeaderPageComponent from "../../components/header-page.component";
import UserDetailComponent from "./user-detail.component";
import PointHistoryComponent from "./point-history.component";
import {useParams} from "react-router-dom";
import {getUserDetailApi, getUserPointListApi} from "../../api/user.api";
import {useDispatch, useSelector} from "react-redux";
import {USER_REDUCER} from "../../reducers/user.reducer";

const UserDetailPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {pointListPage, pointListLimit} = useSelector(state => state.userReducer)

    useEffect(() => {
        let params = {
            user_id: id,
            sort_by: 'created_at-desc',
            page: pointListPage,
            limit: pointListLimit
        }
        getUserPointListApi(params)
            .then(response => {
                dispatch({
                    type: USER_REDUCER,
                    payload: {
                        pointListTotalData: response.meta.total_data,
                        pointList: response.data
                    }
                })
            })
            .catch(err => console.log(err.response))
    }, [pointListPage])

    useEffect(() => {
        getUserDetailApi({user_id: id})
            .then(response => {
                dispatch({type: USER_REDUCER, payload: {userDetail: response}})
            })
            .catch(err => {
                console.log(err.response)
                alert(err.response.data.detail)
                window.location.href = '/user/list'
            })

        return () => {
            dispatch({type: USER_REDUCER, payload: {userDetail: {}}})
        }
    }, [])
    return (
        <>
            <HeaderPageComponent title={'User List'} subTitle={'User Detail'}/>
            <div style={{marginTop: 100}}>
                <UserDetailComponent/>
                <PointHistoryComponent/>
            </div>
        </>
    )
}

export default UserDetailPage
