import React from "react";
import {Button, Row, Col, Card} from "antd";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import {ReactComponent as PointLogo} from "../../images/icon/icon-point@2x.svg";
import BackgroundUserDetail from "../../images/bg-user-detail/bg-user-detail.png";
import {Link} from "react-router-dom";
import PointModal from "../../components/point.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {useDispatch, useSelector} from "react-redux";
import {convertPhoneNumber} from "../../ui-util/general-variable";


const UserDetailComponent = () => {
    const dispatch = useDispatch();
    const {point, User} = useSelector(state => state.userReducer.userDetail)
    const {adminDetail} = useSelector(state => state.adminReducer)

    return (
        <Card style={{
            width: 950,
            backgroundImage: `url(${BackgroundUserDetail})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            borderRadius: 12
        }}>
            <Row>
                <Col span={8} style={{height: 250}}>
                    <div style={Styles.fontArmyGrayBold}>Info User</div>
                    <div style={{
                        fontSize: 22,
                        marginTop: 20,
                        marginBottom: 15
                    }}>{User?.first_name} {User?.last_name}</div>
                    <div style={{color: Colors.darkGrey}}>{User?.email}</div>
                    <div style={{
                        color: Colors.darkGrey,
                        marginTop: 10,
                        marginBottom: 25
                    }}>{!!User?.phone_number && convertPhoneNumber(User?.phone_number)}</div>
                    {
                        adminDetail?.capability_slugs?.includes('update-user') &&
                        <Link to={`/user/update/${User?.id}`}><Button style={{...Styles.buttonWithRadius, height: 40}}>Edit
                            User</Button></Link>}
                </Col>
                <Col span={8}>
                    <div style={Styles.fontArmyGrayBold}>Jumlah Poin</div>
                    <Card style={{width: 250, marginTop: 20, borderRadius: 8, borderColor: Colors.hexGray, top: 0}}>
                        <Row>
                            <PointLogo/>
                            <div style={{marginLeft: 15}}>
                                <div style={{fontSize: 20, fontWeight: 'bold'}}>{point} Points</div>
                                {
                                    adminDetail?.capability_slugs?.includes('update-user') &&
                                    <Row justify={'space-between'} style={{marginTop: 10, width: 140}}>
                                        <div style={Styles.fontArmyGrayBold} onClick={() => dispatch({
                                            type: MODAL_REDUCER,
                                            payload: {pointModalVisible: true, pointModalType: 'minus'}
                                        })}>Kurangi
                                        </div>
                                        <div style={Styles.fontArmyGrayBold} onClick={() => dispatch({
                                            type: MODAL_REDUCER,
                                            payload: {pointModalVisible: true, pointModalType: 'plus'}
                                        })}>Tambah
                                        </div>
                                    </Row>}
                            </div>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <PointModal/>
        </Card>
    )
}

export default UserDetailComponent
