import React, {useEffect} from "react";
import ProductListComponent from "./product-list.component";
import HeaderPageComponent from "../../components/header-page.component";
import ProductFilterComponent from "./product-filter.component";
import {getProductListAllApi, productListApi} from "../../api/product.api";
import {useDispatch, useSelector} from "react-redux";
import {PRODUCT_REDUCER, PRODUCT_REDUCER_INITIAL_STATE} from "../../reducers/product.reducer";

const ProductListPage = () => {
    const dispatch = useDispatch();
    const {currentPage, limit, sort_by, search, reload} = useSelector(state => state.productReducer)

    useEffect(() => {
        let params = {
            sort_by,
            page: currentPage,
            limit,
            search
        }

        getProductList(params)

    }, [limit, currentPage, sort_by, search, reload])

    useEffect(() => {
        return () => {
            dispatch({type: PRODUCT_REDUCER_INITIAL_STATE});
        }
    }, [])

    const getProductList = (params) => {
        dispatch({type: PRODUCT_REDUCER, payload: {loading: true}});
        Promise.all([productListApi(params), getProductListAllApi()])
            .then(response => {
                dispatch({
                    type: PRODUCT_REDUCER,
                    payload: {
                        productList: response[0].data,
                        totalData: response[0].meta.total_data,
                        loading: false,
                        productListAllExport: response[1]
                    }
                });
            })
            .catch(err => {
                console.log('Error productListApi', err)
                dispatch({type: PRODUCT_REDUCER, payload: {loading: true}});
            })
    }

    return (
        <>
            <HeaderPageComponent title={'Product List'}/>
            <div style={{marginTop: 75}}>
                <ProductFilterComponent/>
                <ProductListComponent/>
            </div>
        </>
    )
}

export default ProductListPage
