import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Form, Image, Input, List, Row, Select, Spin, Typography, Upload} from "antd";
import {Colors} from "../../ui-util/colors";
import _, {cloneDeep} from 'lodash';
import {getRoleListApi} from "../../api/role.api";
import {LoadingOutlined} from "@ant-design/icons";
import addImageIcon from "../../images/add-image/add-image@3x.png";
import {uploadImageProductApi} from "../../api/product.api";
import {useDispatch, useSelector} from "react-redux";
import {ADMIN_REDUCER} from "../../reducers/admin.reducer";
import {getCategoryListWithCapabilitiesApi} from "../../api/category.api";
import {ROLE_REDUCER} from "../../reducers/role.reducer";
import {createAdminApi, getAdminDetailApi, updateAdminApi} from "../../api/admin.api";
import {useParams} from "react-router-dom";

const CheckboxGroup = Checkbox.Group;
const {Text} = Typography;
const {Option} = Select;

const AdminForm = (props) => {
    const dispatch = useDispatch();
    const {slug} = props
    const {id} = useParams();
    const formRef = useRef({});
    const [fileImageList, setFileImageList] = useState([])
    const [loading, setLoading] = useState(false)
    const {imageUrl, imageErrorMessage, adminDetail} = useSelector(state => state.adminReducer)
    const {roleList} = useSelector(state => state.roleReducer)
    const [adminImageDetail, setAdminImageDetail] = useState(null)
    const [categoryWithCapabilities, setCategoryWithCapabilities] = useState([])
    const [roleDetailSelected, setRoleDetailSelected] = useState({})
    const [adminForm, setAdminForm] = useState([
        {
            type: 'input',
            key: 'name',
            label: 'Nama Admin',
            placeholder: 'Kartika Putri',
            message: 'Nama admin wajib diisi',
            required: true
        }, {
            type: 'input',
            key: 'email',
            label: 'Email',
            placeholder: 'kartikaputri@gmail.com',
            message: 'Email wajib diisi',
            required: true
        }, {
            type: 'input',
            key: 'phone',
            label: 'Nomor HP',
            placeholder: '081234567890',
            message: 'Nomor HP wajib diisi',
            required: false
        }, {
            type: 'select',
            key: 'role_id',
            label: 'Role',
            placeholder: 'Sales Manager',
            message: 'Role wajib diisi',
            required: true
        }, {
            type: 'input',
            key: 'password',
            label: 'Password',
            placeholder: '123456',
            message: 'Password wajib diisi',
            required: false
        }
    ])

    useEffect(() => {
        Promise.all([getCategoryListWithCapabilitiesApi(), getRoleListApi()])
            .then(response => {
                setCategoryWithCapabilities(response[0])
                dispatch({type: ROLE_REDUCER, payload: {roleList: response[1]}})
            })
            .catch(err => {
                console.log('Error getRoleListApi', err.response)
            })

        return () => {
            dispatch({type: ADMIN_REDUCER, payload: {imageUrl: ''}})
        }
    }, [])

    useEffect(() => {
        if (slug === 'edit') {
            getAdminDetailApi(id)
                .then(response => {
                    formRef.current.setFieldsValue({
                        name: response.name,
                        email: response.email,
                        role_id: response.role_id,
                        phone: response.phone,
                        capability_ids: response.role.capability_ids.concat(response.capability_ids)
                    })
                    setRoleDetailSelected(response.role)
                    setAdminImageDetail(response.image)
                })
                .catch(err => {
                    console.log('Error getAdminDetailApi', err)
                })
        } else {
            let adminFormCreate = cloneDeep(adminForm)
            adminFormCreate[4].required = true
            setAdminForm(adminFormCreate)
        }
    }, [])

    const beforeUploadImage = (fileImage) => {
        if (fileImage.size > 1000000) {
            alert('Ukuran gambar terlalu besar. Maks 1 MB')
        } else {
            setFileImageList([fileImage])
            setLoading(true)
            dispatch({type: ADMIN_REDUCER, payload: {imageErrorMessage: false}})
        }
    }

    const uploadImage = () => {
        const form = new FormData();
        form.append('image', fileImageList[0])
        uploadImageProductApi(form)
            .then(response => {
                fileImageList[0].url = response.image_url
                setFileImageList(fileImageList)
                setLoading(false)
                dispatch({type: ADMIN_REDUCER, payload: {imageUrl: response.image_url}})
            })
            .catch(err => {
                setLoading(false)
                console.log('Err uploadImageProductApi', err)
            })
    }

    const onFinish = (values) => {
        let adminCapability = roleDetailSelected.capability_ids.length > 0 ? _.slice(values.capability_ids, roleDetailSelected.capability_ids.length, values.capability_ids.length) : []
        let requestBody = {
            email: values.email,
            name: values.name,
            role_id: values.role_id,
            image: !!imageUrl ? imageUrl : adminImageDetail,
            capability_ids: adminCapability,
            phone: !!values.phone ? values.phone : '',
            password: values.password
        }
        if(!!values.password){
            requestBody.password = values.password
        }

        if (slug === 'edit') {
            updateAdmin(requestBody)
        } else {
            createAdmin(requestBody)
        }
    }

    const createAdmin = (requestBody) => {
        createAdminApi(requestBody)
            .then(response => {
                alert('Admin berhasil di buat!')
                window.location.href = '/admin/admin-list'
            })
            .catch(err => {
                console.log('Err createAdminApi', err)
                alert(err.detail)
            })
    }

    const updateAdmin = (requestBody) => {
        updateAdminApi(id, requestBody)
            .then(response => {
                alert('Admin detail berhasil di ubah!')
                window.location.href = '/admin/admin-list'
            })
            .catch(err => {
                console.log('Err updateAdminApi', err.response)
            })
    }

    const onSelectRole = (selectedId) => {
        let index = _.findIndex(roleList, ['id', selectedId]);
        formRef.current.setFieldsValue({capability_ids: roleList[index].capability_ids})
        setRoleDetailSelected(roleList[index])
    }

    return (
        <>
            <Form
                ref={formRef}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row style={{marginTop: 75}}>
                    <div style={{marginTop: 10, marginRight: 75}}>
                        <Upload
                            customRequest={uploadImage}
                            beforeUpload={beforeUploadImage}
                            fileList={fileImageList}
                            showUploadList={false}
                        >
                            {
                                !!imageUrl ?
                                    <Image style={{width: 236, height: 236}} src={imageUrl} preview={false}/> :
                                    loading ? <Spin style={{width: 236, marginTop: 115}}
                                                    indicator={<LoadingOutlined
                                                        style={{color: Colors.armyGray, fontSize: 30}}/>}/> :
                                        <Image style={{width: 236, height: 236}}
                                               src={!!adminImageDetail ? adminImageDetail : addImageIcon}
                                               preview={false}/>
                            }
                        </Upload>
                        {
                            imageErrorMessage &&
                            <div style={{textAlign: 'center', fontSize: 12, color: 'red'}}>Foto produk wajib
                                diisi</div>}
                    </div>
                    <List
                        dataSource={adminForm}
                        renderItem={(item) => {
                            switch (item.type) {
                                case 'select':
                                    return (
                                        <Form.Item
                                            name={item.key}
                                            label={<div
                                                style={{
                                                    color: Colors.darkGreen,
                                                    fontWeight: 'bold'
                                                }}>{item.label}</div>}
                                            rules={[{required: item.required, message: item.message}]}
                                        >
                                            <Select
                                                placeholder="Pilih kategori"
                                                allowClear
                                                style={{width: 400}}
                                                onSelect={(id) => onSelectRole(id)}
                                            >
                                                {
                                                    roleList.map((item) => <Option key={item.id}
                                                                                   value={item.id}>{item.name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                default:
                                    return (
                                        <Form.Item
                                            name={item.key}
                                            label={<div
                                                style={{
                                                    color: Colors.darkGreen,
                                                    fontWeight: 'bold'
                                                }}>{item.label}</div>}
                                            rules={[{
                                                required: item.required,
                                                message: item.message
                                            }]}
                                        >
                                            <Input
                                                disabled={item.key === 'email' && slug === 'edit'}
                                                style={{
                                                    width: 400,
                                                    height: 40,
                                                    borderRadius: 12,
                                                    color: Colors.armyGray
                                                }} placeholder={item.placeholder}/>
                                        </Form.Item>
                                    )
                            }
                        }}
                    />
                </Row>
                <Form.Item name={'capability_ids'}>
                    <CheckboxGroup style={{width: '100%'}}>
                        <Text style={{
                            fontFamily: 'Belleza-Regular',
                            fontSize: 32,
                            color: Colors.armyGray
                        }}>Additional Capabilities</Text>
                        <div style={{height: 1, backgroundColor: Colors.lightgray}}/>
                        <List
                            grid={{column: 5}}
                            style={{marginTop: 30}}
                            dataSource={categoryWithCapabilities}
                            renderItem={(category) => (
                                <>
                                    <Text style={{
                                        fontFamily: 'Belleza-Regular',
                                        fontSize: 24,
                                        color: Colors.armyGray
                                    }}>{category.name}</Text>
                                    {
                                        category.capabilities.length > 0 &&
                                        <List
                                            style={{marginTop: 30}}
                                            dataSource={category.capabilities}
                                            renderItem={(capabilities) => (
                                                <Row
                                                    align={'center'}
                                                    style={{
                                                        width: 200,
                                                        backgroundColor: 'white',
                                                        height: 40,
                                                        alignItems: 'center',
                                                        marginBottom: 20,
                                                        borderRadius: 12
                                                    }}>
                                                    <Checkbox
                                                        disabled={!!roleDetailSelected.name && roleDetailSelected.capability_ids.includes(capabilities.id)}
                                                        style={{color: Colors.armyGray}}
                                                        value={capabilities.id}>{capabilities.name}</Checkbox>
                                                    <br/>
                                                </Row>
                                            )}
                                        />}
                                </>
                            )}
                        />
                    </CheckboxGroup>
                </Form.Item>
                {
                    ((adminDetail?.capability_slugs?.includes('create-admin') &&
                        slug === 'create')
                        ||
                        (adminDetail?.capability_slugs?.includes('update-admin') &&
                            slug === 'edit')) &&
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{
                            height: 40,
                            width: 150,
                            backgroundColor: Colors.armyGray,
                            borderRadius: 12,
                            borderWidth: 0,
                            marginTop: 10
                        }}>
                            {slug === 'edit' ? 'Simpan' : 'Create Admin'}
                        </Button>
                    </Form.Item>
                }
            </Form>
        </>

    )
}

export default AdminForm
