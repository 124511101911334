import {combineReducers} from 'redux';
import {authReducer} from "./auth.reducer";
import {productReducer} from "./product.reducer";
import {modalReducer} from "./modal.reducer";
import {importReducer} from "./import.reducer";
import {userReducer} from "./user.reducer";
import {categoryReducer} from "./category.reducer";
import {capabilityReducer} from "./capability.reducer";
import {roleReducer} from "./role.reducer";
import {adminReducer} from "./admin.reducer";

export default combineReducers({
    authReducer,
    productReducer,
    modalReducer,
    importReducer,
    userReducer,
    categoryReducer,
    capabilityReducer,
    roleReducer,
    adminReducer
})
