import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import RoleForm from "./role.form";

const CreateRolePage = () => {
    return (
        <>
            <HeaderPageComponent title={'Create Role'}/>
            <RoleForm slug={'create'}/>
        </>
    )
}

export default CreateRolePage
