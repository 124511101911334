const token = localStorage.getItem('TOKEN');

const DEFAULT_API_HEADER = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

const DEFAULT_API_HEADER_WITH_TOKEN = {
    'Accept': 'application/json',
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`
};

const API_HEADER_FORM_DATA_WITH_TOKEN = {
    'Accept': 'application/json',
    'content-type': 'multipart/form-data',
    'Authorization': `Bearer ${token}`
};

export {
    DEFAULT_API_HEADER,
    DEFAULT_API_HEADER_WITH_TOKEN,
    API_HEADER_FORM_DATA_WITH_TOKEN
}
