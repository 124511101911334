import React, {useState} from "react";
import {Row, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import {ReactComponent as PointLogo} from "../../images/icon/icon-point.svg";
import {convertPhoneNumber, getDateCustomFormat} from "../../ui-util/general-variable";
import {Styles} from "../../ui-util/styles";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as ProfileImageLogo} from "../../images/sidebar/profile-image.svg";
import {USER_REDUCER} from "../../reducers/user.reducer";
import {Link} from "react-router-dom";

const UserListComponent = () => {
    const dispatch = useDispatch();
    const {userList, selectedRowKeys, totalData, currentPage, limit, loading, tabKey} = useSelector(state => state.userReducer)
    const [columns] = useState([
        {
            key: 'image', title: '',
            render: () => <ProfileImageLogo/>,
            align: 'center'
        }, {
            key: 'name', dataIndex: 'User', title: 'Name',
            render: (user) => <div style={Styles.fontArmyGrayBold}>{`${user?.first_name} ${!!user.last_name ? user?.last_name : 'test'}`}</div>
        }, {
            key: 'email', dataIndex: 'User', title: 'Email',
            render: (user) => <div style={{color: Colors.armyGray}}>{user.email}</div>
        }, {
            key: 'phone', dataIndex: 'User', title: 'No.Hp',
            render: (user) => <div style={{color: Colors.darkGrey}}>{convertPhoneNumber(user.phone_number)}</div>
        }, {
            key: 'point', dataIndex: 'point', title: 'Jumlah Poin',
            render: (point) => <Row style={{color: Colors.darkGrey, alignItems: 'center'}}>
                <PointLogo/>
                <div style={{marginLeft: 10}}>{!!point ? point : 0} Points</div>
            </Row>
        }, {
            key: 'createdDate', dataIndex: 'User', title: 'Created Date',
            render: (user) => <div
                style={{color: Colors.darkGrey}}>{!!user.created_at && getDateCustomFormat(user.created_at, 'DD MMM YYYY HH:mm')}</div>
        }, {
            key: 'edit', title: '',
            render: (record) =><Link to={`/user/detail/${record.User.id}`}><div style={Styles.fontArmyGrayBold} >Edit</div></Link>
        }
    ])
    const [columnsWithoutPoint] = useState([
        {
            key: 'image', title: '',
            render: () => <ProfileImageLogo/>,
            align: 'center'
        }, {
            key: 'name', dataIndex: 'User', title: 'Name',
            render: (user) => <div style={Styles.fontArmyGrayBold}>{`${user.first_name} ${!!user.last_name ? user?.last_name : ''}`}</div>
        }, {
            key: 'email', dataIndex: 'User', title: 'Email',
            render: (user) => <div style={{color: Colors.armyGray}}>{user.email}</div>
        }, {
            key: 'phone', dataIndex: 'User', title: 'No.Hp',
            render: (user) => <div style={{color: Colors.darkGrey}}>{convertPhoneNumber(user.phone_number)}</div>
        }, {
            key: 'createdDate', dataIndex: 'User', title: 'Created Date',
            render: (user) => <div
                style={{color: Colors.darkGrey}}>{!!user.created_at && getDateCustomFormat(user.created_at, 'DD MMM YYYY HH:mm')}</div>
        }, {
            key: 'edit', title: '',
            render: (record) =><Link to={`/user/detail/${record.User.id}`}><div style={Styles.fontArmyGrayBold} >Edit</div></Link>
        }
    ])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch({type: USER_REDUCER, payload: {selectedRowKeys, selectedRows}});
        }

    }

    const onChangePagination = (current) => {
        dispatch({type: USER_REDUCER, payload: {currentPage: current, selectedRowKeys: []}});
    }

    return (
        <Table
            columns={tabKey === 'point' ? columns : columnsWithoutPoint}
            dataSource={userList}
            rowKey={record => record.User.id}
            rowSelection={rowSelection}
            loading={loading}
            pagination={{
                position: ['bottomCenter'],
                total: totalData,
                current: currentPage,
                onChange: onChangePagination,
                pageSize: limit,
                showSizeChanger: false,
            }}
        />
    )
}

export default UserListComponent
