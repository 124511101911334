import React from "react";
import {Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {PRODUCT_REDUCER} from "../../reducers/product.reducer";
import ExportProduct from "../../components/export-product.component";
import {deleteProductApi} from "../../api/product.api";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import StandardModal from "../../components/standard.modal";
import DeleteButtonComponent from "../../components/delete-button.component";
import PageSizeComponent from "../../components/page-size.component";
import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";

const ProductFilterComponent = () => {
    const dispatch = useDispatch();
    const {selectedRows, reload, selectedRowKeys, productListAllExport} = useSelector(state => state.productReducer)
    const {adminDetail} = useSelector(state => state.adminReducer)

    const deleteProductHandler = () => {
        deleteProductApi(selectedRowKeys)
            .then(response => {
                dispatch({type: PRODUCT_REDUCER, payload: {reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {isModalVisible: false}})
                alert('Product berhasil di hapus!')
            })
            .catch(err => {
                console.log('Error deleteProductHandler', err)
            })
    }


    return (
        <Row justify={'space-between'} style={{marginBottom: 50, marginTop: 30}}>
            <SearchComponent title={'produk'} onPressEnter={(e) => dispatch({
                type: PRODUCT_REDUCER,
                payload: {search: e.target.value, currentPage: 1}
            })}/>

            <SortComponent page={'product'}
                           onSelect={(item) => dispatch({type: PRODUCT_REDUCER, payload: {sort_by: item}})}
            />

            <PageSizeComponent
                onSelect={(item) => dispatch({type: PRODUCT_REDUCER, payload: {limit: item, currentPage: 1}})}/>

            <ExportProduct data={selectedRows}/>

            <ExportProduct data={productListAllExport} allData={true}/>

            {adminDetail?.capability_slugs?.includes('delete-product') &&
            <DeleteButtonComponent dataLength={selectedRows.length} deleteContent={'produk'}/>}

            <StandardModal onOkHandler={() => deleteProductHandler()}/>
        </Row>
    )
}

export default ProductFilterComponent
