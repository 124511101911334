import axios from "axios";
import {baseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER_WITH_TOKEN} from "./api.config";

const getRoleListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/role/`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const createRoleApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/role/create`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getRoleDetailApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/role/${id}`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateRoleApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/role/update/${id}`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const deleteRoleApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/role/delete/bulk`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    getRoleListApi,
    createRoleApi,
    getRoleDetailApi,
    updateRoleApi,
    deleteRoleApi
}
