import axios from "axios";
import {baseUrl} from "../ui-util/general-variable";
import {API_HEADER_FORM_DATA_WITH_TOKEN} from "./api.config";

const uploadImageAdminApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/admin/upload/image`,
            requestBody,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const createAdminApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/admin/create`,
            requestBody,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
};

const getAdminListApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/admin/list`,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN,
                params
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getAdminDetailApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/admin/${id}`,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const deleteAdminApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/admin/delete/bulk`,
            requestBody,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateAdminApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/admin/update/${id}`,
            requestBody,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getCurrentAdminApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/admin/`,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    uploadImageAdminApi,
    createAdminApi,
    getAdminListApi,
    getAdminDetailApi,
    deleteAdminApi,
    updateAdminApi,
    getCurrentAdminApi
}
