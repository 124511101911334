import React, {useState} from "react";
import {Colors} from "../ui-util/colors";
import {Button, Row, Select} from "antd";
import {ReactComponent as SortingPointIcon} from "../images/icon/icon-sorting-point.svg";

const {Option} = Select;

const SortComponent = (props) => {
    const {page, onSelect} = props
    const [productSortingList] = useState([
        {
            key: 'product_name-asc',
            label: 'Product Name Ascending'
        }, {
            key: 'product_name-desc',
            label: 'Product Name Descending'
        }, {
            key: 'value-asc',
            label: 'Point Low to High'
        }, {
            key: 'value-desc',
            label: 'Point High to Low'
        }, {
            key: 'sku-asc',
            label: 'SKU Low to High'
        }, {
            key: 'sku-desc',
            label: 'SKU High to Low'
        }
    ])
    const [userSortingList] = useState([
        {
            key: 'name-asc',
            label: 'Name A to Z'
        }, {
            key: 'name-desc',
            label: 'Name Z to A'
        }, {
            key: 'point-asc',
            label: 'Point Low to High'
        }, {
            key: 'point-desc',
            label: 'Point High to Low'
        },
    ])
    const [userWithoutPointSortingList] = useState([
        {
            key: 'name-asc',
            label: 'Name A to Z'
        }, {
            key: 'name-desc',
            label: 'Name Z to A'
        }
    ])

    const sortingList = () => {
        let sort = []
        switch (page) {
            case 'product':
                sort = productSortingList
                break;
            case 'user':
                sort = userSortingList
                break;
            default:
                sort = userWithoutPointSortingList
        }
        return sort
    }


    return (
        <Button size={'large'} style={{
            borderRadius: 10,
            borderWidth: 1,
            borderColor: Colors.armyGray,
            backgroundColor: Colors.softArmy,
            width: 260
        }}>
            <Row justify={'center'} style={{alignItems: 'center'}}>
                <SortingPointIcon/>
                <Select style={{color: Colors.armyGray, width: 200}} bordered={false}
                        defaultValue={page === 'product' ? 'Point Low to High' : 'Name A to Z'} showArrow={false}
                        onSelect={onSelect}
                >
                    {
                        sortingList().map((item, index) =>
                            <Option value={item.key} key={index.toString()}>{item.label}</Option>
                        )
                    }
                </Select>
            </Row>
        </Button>
    )
}

export default SortComponent
