import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import ProductForm from "./product.form";

const CreateProductPage = () => {

    return (
        <>
            <HeaderPageComponent title={'Create Product'}/>
            <ProductForm slug={'create'}/>
        </>
    )
}

export default CreateProductPage
