const INITIAL_STATE = {
    roleList: [],
    selectedRowKeys: [],
    loading: false,
    reload: false
}

export const ROLE_REDUCER = 'ROLE_REDUCER'

export const roleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ROLE_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
