import axios from "axios";
import {baseUrl} from "../ui-util/general-variable";
import {API_HEADER_FORM_DATA_WITH_TOKEN, DEFAULT_API_HEADER_WITH_TOKEN} from "./api.config";

const productListApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/product/`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
                params
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const deleteProductApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/product/delete/bulk`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const createProductApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/product/create`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const uploadImageProductApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/product/upload/image`,
            requestBody,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateProductApi = (sku, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/product/update/${sku}`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getProductDetailApi = (sku) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/product/${sku}`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const importProductCreateApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/product/import/create`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const importProductUpdateApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/product/import/update`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getProductListAllApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/product/all`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    productListApi,
    deleteProductApi,
    createProductApi,
    uploadImageProductApi,
    updateProductApi,
    getProductDetailApi,
    importProductCreateApi,
    importProductUpdateApi,
    getProductListAllApi
};
