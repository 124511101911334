const INITIAL_STATE = {
    capabilityList: [],
    selectedRowKeys: [],
    loading: false,
    capabilityDetail: {},
    reload: false
}

export const CAPABILITY_REDUCER = 'CAPABILITY_REDUCER'

export const capabilityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CAPABILITY_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
