import axios from "axios";
import {baseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER_WITH_TOKEN} from "./api.config";

const categoryListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/category/`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const createCategoryApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/category/create`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
};

const getCategoryDetailApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/category/${id}`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateCategoryApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/category/update/${id}`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const deleteCategoryApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/category/delete/bulk`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getCategoryListWithCapabilitiesApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/category/list-with-capabilities`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    categoryListApi,
    createCategoryApi,
    getCategoryDetailApi,
    updateCategoryApi,
    deleteCategoryApi,
    getCategoryListWithCapabilitiesApi
}
