import React, {useEffect, useState} from 'react'
import {Button, Col, Image, Layout, Menu, Popover, Row, Avatar} from "antd";
import logoAvoskinPointEngine from '../images/logo-avoskin-point-engine/logo-avoskin-point-engine@3x.png'
import {Colors} from "../ui-util/colors";
import {ReactComponent as BoxComponent} from '../images/sidebar/icon-product.svg';
import {ReactComponent as BoxGreenComponent} from '../images/sidebar/icon-product-green.svg';
import {ReactComponent as UserLogo} from '../images/sidebar/icon-user.svg'
import {ReactComponent as UserGreenLogo} from '../images/sidebar/002-customer.svg'
import {ReactComponent as AdminSettingLogo} from '../images/sidebar/icon-admin.svg'
import {ReactComponent as IconAdminGreen} from '../images/sidebar/XMLID_2_.svg'
import {ReactComponent as ProfileImageLogo} from '../images/sidebar/profile-image.svg'
import BackgroundSidebar from '../images/background-sidebar/Group 885.png'
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentAdminApi} from "../api/admin.api";
import {ADMIN_REDUCER} from "../reducers/admin.reducer";

const {SubMenu} = Menu;
const {Sider, Footer} = Layout;

const SideBarComponent = () => {
    const dispatch = useDispatch();
    const [openKeys, setOpenKeys] = useState([])
    const {adminDetail} = useSelector(state => state.adminReducer)
    const pathRaw = window.location.pathname.split('/')
    const pathName = pathRaw[1]

    const [sideBarList] = useState([
        {
            title: 'Product',
            key: 'product',
            icon: <BoxComponent/>,
            iconGreen: <BoxGreenComponent/>,
            menuList: [
                {
                    key: '/product/list',
                    slug: 'view-product',
                    label: 'Product List'
                }, {
                    key: '/product/create',
                    slug: 'create-product',
                    label: 'Create Product'
                },
                {
                    key: '/product/import',
                    slug: 'import-product',
                    label: 'Import Product'
                }
            ]
        },
        {
            title: 'User',
            key: 'user',
            icon: <UserLogo/>,
            iconGreen: <UserGreenLogo style={{width: 32}}/>,
            menuList: [
                {
                    key: '/user/list',
                    slug: 'view-user',
                    label: 'User List'
                }, {
                    key: '/user/create',
                    slug: 'create-user',
                    label: 'Create User'
                }
            ]
        }, {
            title: 'Admin Setting',
            icon: <AdminSettingLogo/>,
            iconGreen: <IconAdminGreen style={{width: 32}}/>,
            key: 'admin',
            menuList: [
                {
                    key: '/admin/admin-list',
                    slug: 'view-admin',
                    label: 'Admin List'
                }, {
                    key: '/admin/create-admin',
                    slug: 'create-admin',
                    label: 'Create Admin'
                }, {
                    key: '/admin/role-list',
                    slug: 'view-role',
                    label: 'Role List'
                }, {
                    key: '/admin/create-role',
                    slug: 'create-role',
                    label: 'Create Role'
                }, {
                    key: '/admin/capability-list',
                    slug: 'view-capability',
                    label: 'Capability List'
                }, {
                    key: '/admin/create-capability',
                    slug: 'create-capability',
                    label: 'Create Capability'
                }, {
                    key: '/admin/category-list',
                    slug: 'view-category',
                    label: 'Category List'
                }, {
                    key: '/admin/create-category',
                    slug: 'create-category',
                    label: 'Create Category'
                }, {
                    key: '/admin/point-setting',
                    slug: 'point-setting',
                    label: 'Point'
                },
            ]
        }
    ])

    useEffect(() => {
        getCurrentAdminApi()
            .then(response => {
                dispatch({type: ADMIN_REDUCER, payload: {adminDetail: response}})
            })
            .catch(err => {
                console.log(err)
                localStorage.removeItem('TOKEN')
            })
    }, [])

    useEffect(() => {
        const pathRaw = window.location.pathname.split('/')
        const pathName = pathRaw[1]
        setOpenKeys([pathName])
    }, [])

    const content = (
        <Col>
            <Button style={{width: 150, color: Colors.darkGrey, marginBottom: 10}} onClick={() => {
                window.location.href = `/admin/admin/update/${adminDetail?.id}`
            }} ghost>Edit Profile</Button><br/>
            <Button
                onClick={() => {
                    localStorage.removeItem('TOKEN')
                    window.location.href = '/'
                }}
                style={{
                    width: 150,
                    borderColor: Colors.palevioletred,
                    backgroundColor: Colors.seashell,
                    borderRadius: 12,
                    color: Colors.palevioletred
                }}>Logout</Button>
        </Col>
    );

    const checkingAdmin = () => {
        switch (true){
            case adminDetail?.category_slugs?.includes('role'):
            case adminDetail?.category_slugs?.includes('capability'):
            case adminDetail?.category_slugs?.includes('category'):
                return true
            default:
                return false
        }
    }

    return (
        <Sider
            width={250}
            style={{
                minHeight: '100vh',
                backgroundColor: 'white',
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}>
            <Image src={logoAvoskinPointEngine} style={{marginTop: 50, padding: 20}} preview={false}/>
            <Menu
                defaultOpenKeys={[pathName]}
                onOpenChange={(keys) => setOpenKeys(keys)}
                mode="inline"
                style={{
                    marginLeft: 20,
                    marginRight: 20,
                    width: 200,
                    marginTop: 20,
                    minHeight: window.screen.height - 380,
                    borderWidth: 0
                }}
            >
                {
                    sideBarList.map((item) => {
                        switch (true){
                            case adminDetail?.category_slugs?.includes(item.key):
                            case item.key === 'admin' && checkingAdmin():
                                return (
                                    <SubMenu key={item.key}
                                             icon={openKeys.includes(item.key) ? item.icon : item.iconGreen}
                                             title={item.title}
                                             style={{
                                                 backgroundColor: openKeys.includes(item.key) ? Colors.armyGray : 'white',
                                                 borderRadius: 10,
                                                 color: openKeys.includes(item.key) ? 'white' : Colors.armyGray
                                             }}>
                                        {
                                            item.menuList.map((menu) => {
                                                if (adminDetail?.capability_slugs?.includes(menu.slug)) {
                                                    return (
                                                        <Menu.Item key={menu.key}>
                                                            <Link to={menu.key}>
                                                                <div style={{color: Colors.armyGray}}>{menu.label}</div>
                                                            </Link>
                                                        </Menu.Item>
                                                    )
                                                }
                                            })
                                        }
                                    </SubMenu>
                                )
                        }
                    })
                }
            </Menu>
            <Footer style={{
                backgroundImage: `url(${BackgroundSidebar})`,
                backgroundColor: 'white',
                height: 120,
                bottom: -30,
            }}>
                <Popover placement="topLeft" title={<div style={{color: Colors.armyGray}}>Welcome</div>}
                         content={content} trigger="click"
                         overlayInnerStyle={{borderRadius: 12}}>
                    <Row justify={'space-between'}>
                        <div>
                            {adminDetail?.image ? <Avatar size="large" src={adminDetail?.image}/> : <ProfileImageLogo/>}
                        </div>
                        <div style={{width: '65%'}}>
                            <div style={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: Colors.armyGray,
                            }}>{adminDetail?.name}</div>
                            <div style={{fontSize: 13, color: Colors.armyGray}}>Admin</div>
                        </div>
                    </Row>
                </Popover>
            </Footer>
        </Sider>
    )
}

export default SideBarComponent
