import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import RoleForm from "../create-role/role.form";

const UpdateRolePage = () => {
    return (
        <>
            <HeaderPageComponent title={'Role List'} subTitle={'Update Role'}/>
            <div style={{marginTop: 75}}>
                <RoleForm slug={'edit'}/>
            </div>
        </>
    )
}

export default UpdateRolePage
