const INITIAL_STATE = {
    productList: [],
    productListAllExport: [],
    totalData: 0,
    currentPage: 1,
    limit: 10,
    selectedRowKeys: [],
    selectedRows: [],
    sort_by: 'created_at-desc',
    search: '',
    reload: false,
    imageUrl: '',
    productDetail: {},
    imageErrorMessage: false,
    loading: false
}

export const PRODUCT_REDUCER = 'PRODUCT_REDUCER';
export const PRODUCT_REDUCER_INITIAL_STATE = 'PRODUCT_REDUCER_INITIAL_STATE';

export const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_REDUCER:
            return {...state, ...action.payload}
        case PRODUCT_REDUCER_INITIAL_STATE:
            return {...state, search: '', currentPage: 1,sort_by: 'created_at-desc', selectedRowKeys: [], selectedRows: [], limit: 10}
        default:
            return state
    }
}
