import React, {useEffect, useState} from "react";
import HeaderPageComponent from "../../components/header-page.component";
import {getUserListApi} from "../../api/user.api";
import {useDispatch, useSelector} from "react-redux";
import {USER_REDUCER, USER_REDUCER_INITIAL_STATE} from "../../reducers/user.reducer";
import UserListComponent from "./user-list.component";
import UserFilterComponent from "./user-filter.component";
import {Tabs} from 'antd';
import {Colors} from "../../ui-util/colors";
import {Styles} from "../../ui-util/styles";

const {TabPane} = Tabs;

const UserListPage = () => {
    const dispatch = useDispatch();
    const {currentPage, limit, sort_by, search, tabKey} = useSelector(state => state.userReducer)
    const [tabList] = useState([
        {key: 'basic', label: 'All User'},
        {key: 'point', label: 'User With Point'}
    ])

    useEffect(() => {
        let params = {
            sort_by,
            page: currentPage,
            limit,
            search
        }
        getUserList(params)
    }, [limit, currentPage, search, sort_by, tabKey])

    useEffect(() => {

        return () => {
            dispatch({type: USER_REDUCER_INITIAL_STATE})
        }
    }, [])

    const getUserList = (params) => {
        let url = tabKey === 'point' ? '/point' : ''
        dispatch({type: USER_REDUCER, payload: {loading: true}})

        getUserListApi(params, url)
            .then(response => {
                console.log(response)
                dispatch({
                    type: USER_REDUCER,
                    payload: {userList: response.data, totalData: response.meta.total_data, loading: false}
                })
            })
            .catch(err => {
                console.log('Error getUserListApi', err)
                dispatch({type: USER_REDUCER, payload: {loading: false}})
            })
    }

    return (
        <>
            <HeaderPageComponent title={'User List'}/>
            <div style={{marginTop: 75}}>
                <UserFilterComponent/>
                <Tabs defaultActiveKey={tabKey}
                      tabBarStyle={{color: Colors.armyGray}}
                      onChange={(e) => {
                          dispatch({
                              type: USER_REDUCER,
                              payload: {selectedRowKeys: [], selectedRows: [], limit: 10, tabKey: e}
                          })
                      }}>
                    {
                        tabList.map(item => (
                            <TabPane tab={<span style={Styles.fontArmyGrayBold}>{item.label}</span>} key={item.key}>
                                <UserListComponent/>
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        </>
    )
}

export default UserListPage
