import React from "react";
import {Input, Row} from "antd";
import {ReactComponent as SearchIcon} from "../images/icon/icon-search.svg";

const SearchComponent = (props) => {
    const {onPressEnter, title} = props
    return (
        <Input style={{width: '25%', borderRadius: 10, borderWidth: 1}} size="large" placeholder={`cari ${title}...`}
               onPressEnter={onPressEnter}
               prefix={<Row style={{marginRight: 5}} justify={'center'}><SearchIcon/></Row>}/>
    )
}

export default SearchComponent
