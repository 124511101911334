const INITIAL_STATE = {
    userList: [],
    selectedRowKeys: [],
    selectedRows: [],
    limit: 10,
    search: '',
    sort_by: 'created_at-desc',
    currentPage: 1,
    totalData: 0,
    userDetail: {
        User: {},
        point: 0
    },
    pointListPage: 1,
    pointListLimit: 5,
    pointListTotalData: 0,
    pointList: [],
    loading: false,
    tabKey: 'basic'
}

export const USER_REDUCER = 'USER_REDUCER';
export const USER_REDUCER_INITIAL_STATE = 'USER_REDUCER_INITIAL_STATE'

export const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_REDUCER:
            return {...state, ...action.payload}
        case USER_REDUCER_INITIAL_STATE:
            return {
                ...state,
                search: '',
                currentPage: 1,
                isUserListWithPoint: true,
                sort_by: 'created_at-desc',
                selectedRows: [],
                selectedRowKeys: [],
                limit: 10,
            }
        default:
            return state
    }
}
