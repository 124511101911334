import React from "react";
import {Colors} from "../../ui-util/colors";
import {Button, Card, Col, Row, Upload} from "antd";
import {ReactComponent as IconImport} from "../../images/icon/icon-import.svg";
import {isExcel} from "../../ui-util/general-variable";
import {ExcelRenderer} from "react-excel-renderer";
import {useDispatch, useSelector} from "react-redux";
import {IMPORT_REDUCER} from "../../reducers/import.reducer";
import {Styles} from "../../ui-util/styles";

const UploadFile = () => {
    const dispatch = useDispatch();
    const {fileList} = useSelector(state => state.importReducer)

    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const beforeUpload = (file) => {
        let errorMessage = 'Data tidak ditemukan di dalam file'
        if (isExcel(file)) {
            ExcelRenderer(file, (err, resp) => {
                let newRows = [];
                resp.rows.slice(1).map((row) => {
                    if (row && row !== undefined) {
                        let mandatory = true

                        if(row[0] === undefined || row[1] === undefined || row[2] === undefined){
                            mandatory = false
                        }

                        if(mandatory){
                            newRows.push({
                                sku: row[0],
                                product_name: row[1],
                                value: row[2],
                                image: null
                            });
                        } else {
                            errorMessage = 'Mohon lengkapi sku, nama produk dan point setiap produk !'
                        }
                    }
                });

                if (newRows.length === 0) {
                    alert(errorMessage)
                } else {
                    dispatch({type: IMPORT_REDUCER, payload: {excelJson: newRows, fileList: [file]}})
                }
            })
        } else {
            alert('Mohon masukkan file excel')
        }
    }

    return (
        <div>
            <div style={{marginTop: 50, marginBottom: 50, ...Styles.fontTitle}}>
                Upload Excel File
            </div>
            <Card style={Styles.card}>
                <Row justify={'center'}>
                    <Col justify={'center'} align={"middle"}>
                        <IconImport/>
                        <div style={{color: Colors.darkGreen, marginTop: 17, marginBottom: 17}}>
                            Import product using Excel File
                        </div>
                        <Upload
                            customRequest={dummyRequest}
                            beforeUpload={beforeUpload}
                            fileList={fileList}
                            onRemove={() => dispatch({type: IMPORT_REDUCER, payload: {fileList: []}})}
                        >
                            <Button style={Styles.buttonWithRadius} size={"large"}>Select</Button>
                        </Upload>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default UploadFile
