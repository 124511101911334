import moment from 'moment';
import _ from 'lodash';

const baseUrl = () => {
    return process.env.REACT_APP_BASE_URL
}

const getDateCustomFormat = (dateToFormat, format) => {
    return moment(dateToFormat).format(format);
}

const isExcel = (file) => {
    const excel = file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    return excel
}

const convertPhoneNumber = (value) => {
    let phoneNumber = null;

    if (value.charAt(0) === '0') {
        let tempNumber = _.slice(value, 1).join('');
        phoneNumber = `62${tempNumber}`;
    } else if(value.charAt(0) === '6'){
        phoneNumber = `+${value}`
    } else if (value.includes('+62')) {
        phoneNumber = value;
    } else {
        phoneNumber = ``;
    }

    return phoneNumber;
};

export {
    baseUrl,
    getDateCustomFormat,
    isExcel,
    convertPhoneNumber
}
