import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, List} from "antd";
import {Colors} from "../../ui-util/colors";
import {createCategoryApi, getCategoryDetailApi, updateCategoryApi} from "../../api/category.api";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CATEGORY_REDUCER} from "../../reducers/category.reducer";

const CategoryForm = (props) => {
    const dispatch = useDispatch();
    const formRef = useRef({});
    const {slug} = props
    const {id} = useParams();
    const {adminDetail} = useSelector(state => state.adminReducer)
    const [categoryForm] = useState([
        {
            key: 'name',
            label: 'Name',
            placeholder: 'Products',
            message: 'Nama kategori wajib diisi',
        }, {
            key: 'slug',
            label: 'Slug',
            placeholder: 'products',
            message: 'Slug kategori wajib diisi',
        },
    ])

    useEffect(() => {
        if (slug === 'edit') {
            getCategoryDetailApi(id)
                .then(response => {
                    dispatch({type: CATEGORY_REDUCER, payload: {categoryDetail: response}})
                    formRef.current.setFieldsValue(response)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [])

    const onFinish = (values) => {
        if (slug === 'edit') {
            updateCategory(values)
        } else {
            createCategory(values)
        }
    }

    const createCategory = (values) => {
        createCategoryApi(values)
            .then(response => {
                alert('Category berhasil di buat!')
                window.location.href = '/admin/category-list'
            })
            .catch(err => {
                alert(err.detail)
                console.log(err)
            })
    }

    const updateCategory = (values) => {
        updateCategoryApi(id, values)
            .then(response => {
                alert('Category berhasil di ubah!')
                window.location.href = '/admin/category-list'
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    return (
        <Form
            ref={formRef}
            layout="vertical"
            style={{marginTop: 75}}
            onFinish={onFinish}
        >
            <List
                dataSource={categoryForm}
                renderItem={(item) => (
                    <Form.Item
                        name={item.key}
                        label={<div style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                        rules={[{required: true, message: item.message}]}
                    >
                        <Input
                            style={{
                                width: 400,
                                height: 40,
                                borderRadius: 12
                            }} placeholder={item.placeholder}/>
                    </Form.Item>
                )}
            />
            {
                ((adminDetail?.capability_slugs?.includes('create-category') &&
                    slug === 'create')
                ||
                (adminDetail?.capability_slugs?.includes('update-category') &&
                    slug === 'edit'))
                &&
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{
                        height: 40,
                        width: 150,
                        backgroundColor: Colors.armyGray,
                        borderRadius: 12,
                        borderWidth: 0,
                        marginTop: 10
                    }}>{slug === 'create' ? 'Create Category' : 'Save'}</Button>
                </Form.Item>
            }
        </Form>
    )
}

export default CategoryForm
