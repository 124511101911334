export const Colors = {
    armyGray: '#748d6f',
    softArmy: '#f2f7ef',
    snow: '#f9faf8',
    indianRed: '#b26161',
    darkGrey: '#707070',
    whiteSmoke: '#f5f5f5',
    lightgray: '#c8d1c7',
    darkGreen: '#486a41',
    palevioletred: '#d17f7f',
    seashell: '#ffefef',
    hexGray: '#d6d6d6',
    darkSlateGray: '#333333'
}
