import React, {useEffect} from "react";
import HeaderPageComponent from "../../components/header-page.component";
import {deleteCapabilityApi, getCapabilityListApi} from "../../api/capability.api";
import {useDispatch, useSelector} from "react-redux";
import {CAPABILITY_REDUCER} from "../../reducers/capability.reducer";
import CapabilityListComponent from "./capability-list.component";
import StandardModal from "../../components/standard.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const CapabilityListPage = () => {
    const dispatch = useDispatch();
    const {selectedRowKeys, reload} = useSelector(state => state.capabilityReducer)

    useEffect(() => {
        getCapabilityListApi()
            .then(response => {
                dispatch({type: CAPABILITY_REDUCER, payload: {capabilityList: response}});
            })
            .catch(err => {
                console.log(err.response)
            })
    }, [reload])

    const deleteCapability = () => {
        deleteCapabilityApi(selectedRowKeys)
            .then(response => {
                dispatch({type: CAPABILITY_REDUCER, payload: {reload: !reload, selectedRowKeys: []}})
                dispatch({type: MODAL_REDUCER, payload: {isModalVisible: false}})
                alert('Capability berhasil di hapus!')
            })
            .catch(err => {
                console.log('Error deleteCategoryApi', err.response)
            })
    }

    return (
        <>
            <HeaderPageComponent title={'Capability List'}/>
            <div style={{marginTop: 75}}>
                <CapabilityListComponent/>
            </div>
            <StandardModal onOkHandler={() => deleteCapability()}/>
        </>
    )
}

export default CapabilityListPage
