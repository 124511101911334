const INITIAL_STATE = {
    isSignedIn: false
}

export const AUTH_REDUCER = 'AUTH_REDUCER';

export const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
