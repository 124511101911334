import React from "react";
import ReactExport from "react-export-excel";
import ExportButtonComponent from "./export-button.component";
import {getDateCustomFormat} from "../ui-util/general-variable";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportProduct = (props) => {
    const {data, allData} = props
    return (
        <ExcelFile element={ <ExportButtonComponent dataLength={data.length} allData={allData}/>} filename={'Product'}>
            <ExcelSheet data={data} name="Product List">
                <ExcelColumn label="SKU" value="sku"/>
                <ExcelColumn label="Product Name" value="product_name"/>
                <ExcelColumn label="Point" value="value"/>
                <ExcelColumn label="Created At" value={(col) => !!col.created_at ? getDateCustomFormat(col.created_at, 'DD MMM yyyy HH:mm') : '' }/>
            </ExcelSheet>

        </ExcelFile>
    )
}

export default ExportProduct
