import React from 'react'
import {Button, Card, Form, Image, Input, Row} from 'antd';
import {userSignInApi} from "../../api/auth.api";
import {useDispatch} from "react-redux";
import {AUTH_REDUCER} from "../../reducers/auth.reducer";
import {Colors} from "../../ui-util/colors";
import logoAvoskinPointEngine from "../../images/logo-avoskin-point-engine/point_engine_logo.webp";
import {Link} from "react-router-dom";

const LoginPage = () => {
    const dispatch = useDispatch();

    const onFinish = (values) => {
        userSignInApi(values)
            .then(response => {
                localStorage.setItem('TOKEN', response.access_token)
                dispatch({type: AUTH_REDUCER, payload: {isSignedIn: true}});
                window.location.reload()
            })
            .catch(err => {
                console.log('Error userSignInApi',err)
                alert(err.detail)
            })
    }

    return (
        <div>
            <Row justify={'center'}>
                <Image src={logoAvoskinPointEngine} preview={false}/>
            </Row>
            <div style={{justifyContent: 'center', display: 'flex', marginTop: 30}}>
                <Card style={{width: 577, height: 695, alignItems: 'center'}}>
                    <Form onFinish={onFinish} labelCol={{span: 5}} style={{width: 500, padding: 30}}>
                        <div style={{
                            fontSize: 40,
                            fontFamily: 'Belleza-Regular',
                            color: Colors.armyGray,
                            textAlign: 'center',
                            marginTop: 30,
                            marginBottom: 50
                        }}>Log In
                        </div>
                        <div style={{textAlign: 'center', marginBottom: 10, fontFamily: 'NunitoSans-SemiBold'}}>Email</div>
                        <Form.Item
                            name="username"
                            rules={[{required: true, message: 'Please input your username!'}]}
                        >
                            <Input style={{height: 40, borderRadius: 20}}/>
                        </Form.Item>
                        <div style={{textAlign: 'center', marginBottom: 10, fontFamily: 'NunitoSans-SemiBold'}}>Password</div>
                        <Form.Item
                            name="password"
                            rules={[{required: true, message: 'Please input your password!'}]}
                        >
                            <Input.Password style={{height: 40, borderRadius: 20}}/>
                        </Form.Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Link to={'/auth/forgot-password'}>Forgot Password</Link>
                        </div>
                        <Form.Item wrapperCol={{offset: 7, span: 5}}>
                            <Button type="primary" htmlType="submit"
                                    style={{
                                        width: 200,
                                        height: 40,
                                        backgroundColor: Colors.armyGray,
                                        borderWidth: 0,
                                        borderRadius: 20,
                                        marginTop: 30
                                    }}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default LoginPage
