import React, {useEffect, useState} from "react";
import {Button, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import {getPointSettingApi} from "../../api/point.api";
import EditPointModal from "./edit-point.modal";
import {useSelector} from "react-redux";

const PointSettingList = () => {
    const [pointSetting, setPointSetting] = useState([])
    const [loading, setLoading] = useState(false)
    const [editModalVisibility, setEditModalVisibility] = useState(false)
    const [pointDetail, setPointDetail] = useState({})
    const {reload} = useSelector(state => state.adminReducer)
    const columns = [
        {
            key: 'activity', dataIndex: 'code', title: 'List Activity',
            render: (code) => pointCodeText(code)
        }, {
            key: 'point', dataIndex: 'value', title: 'Nilai Point'
        }, {
            key: 'action', title: 'Action',
            render: (record) => (
                <Button onClick={() => {
                    setEditModalVisibility(true)
                    setPointDetail(record)
                }} style={{
                    borderWidth: 0,
                    color: Colors.armyGray,
                    backgroundColor: 'transparent'
                }}>Edit Point</Button>
            )
        }
    ]

    useEffect(() => {
        setLoading(true)
        Promise.all([getPointSettingApi('BEAUTY_EMPTIES'), getPointSettingApi('INVITATION_TO_ORDER'), getPointSettingApi('LEAVE_REVIEW')])
            .then(response => {
                setLoading(false)
                setPointSetting(response)
            })
            .catch(err => {
                console.log(err.response)
                setLoading(false)
            })
    }, [reload])

    const pointCodeText = (text) => {
        switch (text) {
            case 'BEAUTY_EMPTIES_POINT':
                return 'Beauty Empties'
            case 'INVITATION_TO_ORDER_POINT':
                return 'Invitation To Order'
            case 'LEAVE_REVIEW_POINT':
                return 'Leave Review'
            default:
                return ''
        }
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={pointSetting}
                rowKey="id"
                loading={loading}
            />
            <EditPointModal
                isModalVisible={editModalVisibility}
                onClose={() => setEditModalVisibility(false)}
                pointDetail={pointDetail}/>
        </>

    )
}

export default PointSettingList
