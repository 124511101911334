import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import ProductForm from "../create-product/product.form";

const UpdateProductPage = () => {

    return (
        <>
            <HeaderPageComponent title={'Product List'} subTitle={'Edit Product'}/>
            <ProductForm slug={'edit'}/>
        </>
    )
}

export default UpdateProductPage
