import React from "react";
import {Colors} from "../ui-util/colors";
import {RightOutlined} from '@ant-design/icons';
import {Row} from "antd";

const HeaderPageComponent = (props) => {
    const {title, subTitle} = props
    return (
        <Row align={'middle'}
             style={{
                 position: 'fixed',
                 zIndex: 1,
                 width: '100%',
                 backgroundColor: Colors.snow,
                 top: 0,
                 left: 250,
                 paddingLeft: 40,
                 marginBottom: 50,
                 height: 75
             }}>
            <div style={{
                fontFamily: 'Belleza-Regular',
                fontSize: 32,
                color: Colors.armyGray,
            }}>{title}</div>
            {!!subTitle &&
            <RightOutlined style={{fontSize: 25, color: Colors.armyGray, marginLeft: 30, marginRight: 30}}/>}
            <div style={{
                fontFamily: 'Belleza-Regular',
                fontSize: 32,
                color: Colors.armyGray,
            }}>{subTitle}</div>
        </Row>
    )
}

export default HeaderPageComponent
