import React, {useState} from "react";
import {Button, Image, Row, Table} from 'antd'
import {useDispatch, useSelector} from "react-redux";
import {getDateCustomFormat} from "../../ui-util/general-variable";
import {Colors} from "../../ui-util/colors";
import {ReactComponent as PointLogo} from '../../images/icon/icon-point.svg'
import {PRODUCT_REDUCER} from "../../reducers/product.reducer";
import {Link} from "react-router-dom";
import NoImage from "../../images/no-name.avif"

const ProductListComponent = () => {
    const dispatch = useDispatch();
    const {productList, totalData, currentPage, selectedRowKeys, limit, loading} = useSelector(state => state.productReducer)
    const [columns] = useState([
        {key: 'image', dataIndex: 'image', title: '',
            render: (image) => <Image preview={false} src={!!image ? image : NoImage} style={{width: 100, height: 100}}/>
        },
        {
            key: 'product', dataIndex: 'product_name', title: 'Product',
            render: (product_name) => <div style={{color: Colors.darkGrey}}>{product_name}</div>
        },
        {
            key: 'sku', dataIndex: 'sku', title: 'Kode SKU',
            render: (sku) => <div style={{color: Colors.armyGray}}>{sku}</div>
        },
        {
            key: 'point', dataIndex: 'value', title: 'Nilai Poin',
            render: (value) => <Row style={{color: Colors.darkGrey, alignItems: 'center'}}>
                <PointLogo />
                <div style={{marginLeft: 10}}>{value} Points</div>
            </Row>
        },
        {
            key: 'createdDate', dataIndex: 'created_at', title: 'Created Date',
            render: (created_at) => <div
                style={{color: Colors.darkGrey}}>{getDateCustomFormat(created_at, 'DD MMM YYYY HH:mm')}</div>
        },
        {
            key: 'edit', title: '',
            render: (record) => editButtonHandler(record)
        }
    ])

    const editButtonHandler = (record) => {
        return (
            <Link to={`/product/update/${record.sku}`}>
                <Button onClick={() => dispatch({type: PRODUCT_REDUCER, payload: {productDetail: record}})} style={{borderWidth: 0, color: Colors.armyGray, backgroundColor: 'transparent'}}>Edit</Button>
            </Link>

        )
    }

    const onChangePagination = (current) => {
        dispatch({type: PRODUCT_REDUCER, payload: {currentPage: current}});
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch({type: PRODUCT_REDUCER, payload: {selectedRowKeys, selectedRows}});
        }
    }

    return (
        <Table
            columns={columns}
            dataSource={productList}
            rowKey="sku"
            rowSelection={rowSelection}
            loading={loading}
            pagination={{
                total: totalData,
                current: currentPage,
                onChange: onChangePagination,
                showSizeChanger: false,
                pageSize: limit
            }}
        />
    )
}

export default ProductListComponent
