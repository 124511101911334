import React, {useEffect, useState} from "react";
import HeaderPageComponent from "../../components/header-page.component";
import {Button, Card, Radio, Row, Steps} from "antd";
import {Colors} from "../../ui-util/colors";
import {useDispatch, useSelector} from "react-redux";
import {IMPORT_REDUCER} from "../../reducers/import.reducer";
import {importProductCreateApi, importProductUpdateApi} from "../../api/product.api";
import UploadFile from "./upload-file.component";
import {Styles} from "../../ui-util/styles";

const {Step} = Steps;

const ImportProductPage = () => {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(0);
    const [buttonText, setButtonText] = useState('Next');
    const [chooseButtonList] = useState([
        {
            value: 'create',
            label: 'Create Product'
        }, {
            value: 'update',
            label: 'Update Product'
        }
    ])
    const {importType, excelJson, fileList} = useSelector(state => state.importReducer)

    useEffect(() => {
        return () => {
            dispatch({type: IMPORT_REDUCER, payload: {importType: 'create', fileList: []}})
        }
    }, [])

    const radioButtonOnChange = (e) => {
        dispatch({type: IMPORT_REDUCER, payload: {importType: e.target.value}})
        if (fileList.length > 0) {
            dispatch({type: IMPORT_REDUCER, payload: {fileList: []}})
        }
    }

    const getStepContent = () => {
        switch (current) {
            case 0:
                return (
                    <Row justify={'center'} align={"middle"} style={{height: 400}}>
                        <Radio.Group defaultValue={importType} size="large" onChange={(e) => radioButtonOnChange(e)}>
                            {
                                chooseButtonList.map(item => (
                                    <Radio.Button key={item.value} value={item.value}
                                                  style={importType === item.value ? {
                                                      backgroundColor: Colors.armyGray,
                                                      color: 'white',
                                                      borderColor: Colors.armyGray
                                                  } : {color: Colors.armyGray}}>
                                        {item.label}</Radio.Button>
                                ))
                            }
                        </Radio.Group>
                    </Row>
                )
            case 1:
                return (<UploadFile/>)
            case 2:
                return (
                    <Row justify={'center'} align={"middle"} style={{height: 400}}>
                        <div>Berhasil</div>
                    </Row>
                )
            default:
                return 0
        }
    }

    const nextButtonHandler = () => {
        if (current === 1 && fileList.length > 0) {
            importProduct()
        } else if (current === 1 && fileList.length === 0) {
            alert('Anda belum mengupload file excel')
        } else if (current === 2) {
            window.location.href = '/product/list'
        } else {
            setCurrentAndButtonText(1, 'Upload')
        }
    }

    const setCurrentAndButtonText = (value, text) => {
        setCurrent(current + value)
        setButtonText(text)
    }

    const importProduct = () => {
        const importApi = importType === 'create' ? importProductCreateApi : importProductUpdateApi
        importApi(excelJson)
            .then(response => {
                alert(response.message)
                if (response.message.includes('all')) {
                    setCurrentAndButtonText(1, 'Done')
                }
            })
            .catch(err => console.log('Error importApi', err))
    }

    const buttonNextPrevRender = () => (
        <Row justify={'end'}>
            {
                current === 1 &&
                <Button onClick={() => setCurrentAndButtonText(-1, 'Next')}
                        style={{...Styles.button, marginRight: 20}}>Prev</Button>}
            {
                <Button onClick={() => nextButtonHandler()}
                        style={Styles.button}>{buttonText}</Button>}
        </Row>
    )
    return (
        <>
            <HeaderPageComponent title={'Import Product'}/>
            <Card style={{borderRadius: 12, marginTop: 75}}>
                <Steps progressDot current={current} style={{color: Colors.armyGray}}>
                    {
                        ['Choose', 'Import', 'Done'].map((item) => (
                            <Step key={item} title={item}/>
                        ))
                    }
                </Steps>
                <div style={{padding: 10, height: 400}}>{getStepContent()}</div>
                {buttonNextPrevRender()}
            </Card>
        </>
    )
}

export default ImportProductPage
