import React, {useEffect, useRef} from "react";
import {Button, Form, Input, List} from "antd";
import {Colors} from "../../ui-util/colors";
import {updatePointSettingApi} from "../../api/point.api";
import {ADMIN_REDUCER} from "../../reducers/admin.reducer";
import {useDispatch, useSelector} from "react-redux";

const EditPointForm = ({pointDetail, onClose}) => {
    const formRef = useRef({});
    const dispatch = useDispatch();
    const {reload} = useSelector(state => state.adminReducer)
    const pointSettingForm = [
        {
            key: 'code',
            label: 'Nama Activity',
            placeholder: 'Nama Activity',
            message: 'Nama activity wajib diisi',
            disabled: true
        }, {
            key: 'value',
            label: 'Point',
            placeholder: 'point',
            message: 'Point wajib diisi',
        },
    ]

    useEffect(() => {
        formRef.current.setFieldsValue(pointDetail)
    }, [pointDetail])

    const pointCodeText = (text) => {
        switch (text) {
            case 'BEAUTY_EMPTIES_POINT':
                return 'BEAUTY_EMPTIES'
            case 'INVITATION_TO_ORDER_POINT':
                return 'INVITATION_TO_ORDER'
            case 'LEAVE_REVIEW_POINT':
                return 'LEAVE_REVIEW'
            default:
                return ''
        }
    }

    const onFinish = (values) => {
        updatePointSettingApi(pointCodeText(values.code), {value: String(values.value)})
            .then(response => {
                dispatch({type: ADMIN_REDUCER, payload: {reload: !reload}});
                onClose()
            })
            .catch(err => console.log(err.response))
    }

    return (
        <Form
            ref={formRef}
            layout="vertical"
            onFinish={onFinish}
        >
            <List
                dataSource={pointSettingForm}
                renderItem={(item) => (
                    <Form.Item
                        name={item.key}
                        label={<div style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                        rules={[{required: true, message: item.message}]}
                    >
                        <Input
                            style={{
                                width: 400,
                                height: 40,
                                borderRadius: 12
                            }}
                            placeholder={item.placeholder}
                            disabled={item.disabled}
                        />
                    </Form.Item>
                )}
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" style={{
                    height: 40,
                    width: 150,
                    backgroundColor: Colors.armyGray,
                    borderRadius: 12,
                    borderWidth: 0,
                    marginTop: 10
                }}>Save</Button>
            </Form.Item>
        </Form>
    )
}

export default EditPointForm
