import React, {useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginPage from "../pages/login";
import ProductListPage from "../pages/product-list";
import CreateProductPage from "../pages/create-product";
import UpdateProductPage from "../pages/update-product";
import ImportProductPage from "../pages/import-product";
import UserListPage from "../pages/user-list";
import UserDetailPage from "../pages/user-detail";
import CreateUserPage from "../pages/create-user";
import UpdateUserPage from "../pages/update-user";
import CategoryListPage from "../pages/category-list";
import CreateCategoryPage from "../pages/create-category";
import UpdateCategoryPage from "../pages/update-category";
import CapabilityListPage from "../pages/capability-list";
import CreateCapabilityPage from "../pages/create-capability";
import UpdateCapabilityPage from "../pages/update-capability";
import RoleListPage from "../pages/role-list";
import CreateRolePage from "../pages/create-role";
import UpdateRolePage from "../pages/update-role";
import CreateAdminPage from "../pages/create-admin";
import AdminListPage from "../pages/admin-list";
import UpdateAdminPage from "../pages/update-admin";
import {PrivateRoute} from "./PrivateRoute";
import PointSetting from "../pages/point-setting";
import ForgotPassword from "../pages/forgot-password";

export default function RoutesPage() {
    const token = localStorage.getItem('TOKEN')
    const [routeList] = useState([
        {
            path: '/product/create',
            component: <CreateProductPage/>
        }, {
            path: '/product/list',
            component: <ProductListPage/>
        }, {
            path: '/product/update/:sku',
            component: <UpdateProductPage/>
        }, {
            path: '/product/import',
            component: <ImportProductPage/>
        }, {
            path: '/user/list',
            component: <UserListPage/>
        }, {
            path: '/user/detail/:id',
            component: <UserDetailPage/>
        }, {
            path: '/user/create',
            component: <CreateUserPage/>
        }, {
            path: '/user/update/:id',
            component: <UpdateUserPage/>
        }, {
            path: '/admin/category-list',
            component: <CategoryListPage/>
        }, {
            path: '/admin/create-category',
            component: <CreateCategoryPage/>
        }, {
            path: '/admin/category/update/:id',
            component: <UpdateCategoryPage/>
        }, {
            path: '/admin/role-list',
            component: <RoleListPage/>
        }, {
            path: '/admin/create-role',
            component: <CreateRolePage/>
        }, {
            path: '/admin/role/update/:id',
            component: <UpdateRolePage/>
        }, {
            path: '/admin/create-admin',
            component: <CreateAdminPage/>
        }, {
            path: '/admin/admin-list',
            component: <AdminListPage/>
        }, {
            path: '/admin/admin/update/:id',
            component: <UpdateAdminPage/>
        }, {
            path: '/admin/capability-list',
            component: <CapabilityListPage/>
        }, {
            path: '/admin/create-capability',
            component: <CreateCapabilityPage/>
        }, {
            path: '/admin/capability/update/:id',
            component: <UpdateCapabilityPage/>
        }, {
            path: '/admin/point-setting',
            component: <PointSetting/>
        }
    ])

    return (
        <Routes>
            <Route path="/" exact element={!token && <LoginPage/>}/>
            <Route path="/auth/forgot-password" exact element={<ForgotPassword />}/>
            {
                routeList.map((item, index) => (
                    <Route key={index.toString()} path={item.path}
                           element={<PrivateRoute component={item.component}/>}/>
                ))
            }
        </Routes>
    )
}
