import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import CategoryForm from "../create-category/category.form";

const UpdateCategoryPage = () => {
    return (
        <>
            <HeaderPageComponent title={'Category List'} subTitle={'Edit Category'}/>
            <CategoryForm slug={'edit'}/>
        </>
    )
}

export default UpdateCategoryPage
