import React from 'react'
import {Layout} from 'antd';
import SideBarComponent from "./side-bar.component";
import {Colors} from "../ui-util/colors";

const {Content} = Layout;

const DashboardComponent = (props) => {
    const token = localStorage.getItem('TOKEN')

    return (
        <Layout>
            {!!token && <SideBarComponent/>}
            <Layout className="site-layout" style={{backgroundColor: Colors.snow, marginLeft: !!token && 250}}>
                <Content style={{marginLeft: 20, marginRight: 25, padding: 20, minHeight: 1000}}>
                    {props.content}
                </Content>
            </Layout>
        </Layout>
    )
}

export default DashboardComponent
