import axios from "axios";
import {baseUrl} from "../ui-util/general-variable";
import {API_HEADER_FORM_DATA_WITH_TOKEN} from "./api.config";

const getPointSettingApi = (type) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/point/setting/${type}`,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updatePointSettingApi = (type, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/point/setting/${type}`,
            requestBody,
            {
                headers: API_HEADER_FORM_DATA_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    getPointSettingApi,
    updatePointSettingApi
}
