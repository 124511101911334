import axios from "axios";
import {baseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER_WITH_TOKEN} from "./api.config";

const getUserListApi = (params, url) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/user/list${url}`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
                params
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const createUserApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/user/`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const deleteUserApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/user/delete/bulk`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getUserDetailApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/user/${params.user_id}`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
                params
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getUserPointListApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/point/user/${params.user_id}`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
                params
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateUserApi = (requestBody, id) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/user/update/${id}`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateUserPointApi = (params, id) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/point/user/${id}`,
            {},
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
                params
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getUserFileExportApi = () => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl()}/v1/user/export`,
            method: 'GET',
            responseType: 'blob',
            headers: DEFAULT_API_HEADER_WITH_TOKEN
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    getUserListApi,
    createUserApi,
    deleteUserApi,
    getUserDetailApi,
    getUserPointListApi,
    updateUserApi,
    updateUserPointApi,
    getUserFileExportApi
}
