const INITIAL_STATE = {
    categoryList: [],
    selectedRowKeys: [],
    loading: false,
    categoryDetail: {},
    reload: false
}

export const CATEGORY_REDUCER = 'CATEGORY_REDUCER'

export const categoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CATEGORY_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
