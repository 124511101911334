import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import CategoryForm from "./category.form";

const CreateCategoryPage = () => {
    return (
        <>
            <HeaderPageComponent title={'Create Category'}/>
            <CategoryForm slug={'create'} />
        </>
    )
}

export default CreateCategoryPage
