import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, List, Select} from "antd";
import {Colors} from "../../ui-util/colors";
import {useDispatch, useSelector} from "react-redux";
import {createCapabilityApi, getCapabilityDetailApi, updateCapabilityApi} from "../../api/capability.api";
import {useParams} from "react-router-dom";
import {CAPABILITY_REDUCER} from "../../reducers/capability.reducer";
import {categoryListApi} from "../../api/category.api";
import {CATEGORY_REDUCER} from "../../reducers/category.reducer";

const {Option} = Select;

const CapabilityForm = (props) => {
    const dispatch = useDispatch();
    const formRef = useRef({});
    const {slug} = props
    const {id} = useParams();
    const {categoryList} = useSelector(state => state.categoryReducer)
    const {adminDetail} = useSelector(state => state.adminReducer)
    const [capabilityForm] = useState([
        {
            type: 'select',
            key: 'category_id',
            label: 'Category',
            placeholder: 'Category',
            message: 'Nama kategori wajib diisi',
        }, {
            type: 'input',
            key: 'name',
            label: 'Name',
            placeholder: 'Create Product',
            message: 'Nama capability wajib diisi',
        }, {
            type: 'input',
            key: 'slug',
            label: 'Slug',
            placeholder: 'create-product',
            message: 'Slug wajib diisi',
        },
    ])

    useEffect(() => {
        categoryListApi()
            .then(response => {
                dispatch({type: CATEGORY_REDUCER, payload: {categoryList: response}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }, [])

    useEffect(() => {
        if (slug === 'edit') {
            getCapabilityDetail()
        }
    }, [])

    const getCapabilityDetail = () => {
        getCapabilityDetailApi(id)
            .then(response => {
                dispatch({type: CAPABILITY_REDUCER, payload: {capabilityDetail: response}})
                formRef.current.setFieldsValue(response)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const onFinish = (values) => {
        if (slug === 'create') {
            createCapability(values)
        } else {
            updateCapability(values)
        }
    }

    const createCapability = (values) => {
        createCapabilityApi(values)
            .then(response => {
                alert('Capability berhasil di buat!')
                window.location.href = '/admin/capability-list'
            })
            .catch(err => {
                console.log(err)
                alert(err.detail)
            })
    }

    const updateCapability = (values) => {
        updateCapabilityApi(id, values)
            .then(response => {
                alert('Capability berhasil di ubah!')
                window.location.href = '/admin/capability-list'
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Form
            ref={formRef}
            layout="vertical"
            style={{marginTop: 75}}
            onFinish={onFinish}
        >
            <List
                dataSource={capabilityForm}
                renderItem={(item) => {
                    switch (item.type) {
                        case 'select':
                            return (
                                <Form.Item
                                    name={item.key}
                                    label={<div
                                        style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                                    rules={[{required: true, message: item.message}]}
                                >
                                    <Select
                                        placeholder="Pilih kategori"
                                        allowClear
                                        style={{width: 400}}
                                    >
                                        {
                                            categoryList.map((item) => <Option key={item.id}
                                                                               value={item.id}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    name={item.key}
                                    label={<div
                                        style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                                    rules={[{required: true, message: item.message}]}
                                >
                                    <Input
                                        style={{
                                            width: 400,
                                            height: 40,
                                            borderRadius: 12
                                        }} placeholder={item.placeholder}/>
                                </Form.Item>
                            )
                    }
                }}
            />
            {((adminDetail?.capability_slugs?.includes('create-capability') &&
                slug === 'create') ||
                (adminDetail?.capability_slugs?.includes('update-capability') &&
                    slug === 'edit')) &&
            <Form.Item>
                <Button type="primary" htmlType="submit" style={{
                    height: 40,
                    width: 150,
                    backgroundColor: Colors.armyGray,
                    borderRadius: 12,
                    borderWidth: 0,
                    marginTop: 10
                }}>
                    {slug === 'create' ? 'Create Capability' : 'Save'}
                </Button>
            </Form.Item>
            }
        </Form>
    )
}

export default CapabilityForm
