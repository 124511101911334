import {Colors} from "./colors";

export const Styles = {
    button: {
        width: 100,
        height: 40,
        backgroundColor: Colors.armyGray,
        color: 'white',
        borderColor: Colors.armyGray
    },
    card: {
        backgroundColor: Colors.softArmy,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: Colors.lightgray
    },
    fontTitle: {
        fontSize: 24,
        fontFamily: 'Belleza-Regular',
        color: Colors.armyGray
    },
    buttonWithRadius: {
        borderRadius: 12,
        width: 120,
        backgroundColor: Colors.armyGray,
        color: 'white'
    },
    fontArmyGrayBold: {
        color: Colors.armyGray,
        fontWeight: 'bold'
    },
    rowFilter: {
        marginBottom: 50,
        marginTop: 30
    }
}
