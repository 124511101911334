import React, {useEffect} from "react";
import HeaderPageComponent from "../../components/header-page.component";
import {deleteAdminApi, getAdminListApi} from "../../api/admin.api";
import {useDispatch, useSelector} from "react-redux";
import {ADMIN_REDUCER} from "../../reducers/admin.reducer";
import AdminListComponent from "./admin-list.component";
import SearchComponent from "../../components/search.component";
import {Row} from "antd";
import DeleteButtonComponent from "../../components/delete-button.component";
import StandardModal from "../../components/standard.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const AdminListPage = () => {
    const dispatch = useDispatch();
    const {search, selectedRowKeys, reload, adminDetail} = useSelector(state => state.adminReducer)

    useEffect(() => {
        getAdminListApi({search})
            .then(response => {
                dispatch({type: ADMIN_REDUCER, payload: {adminList: response}})
            })
            .catch(err => {
                console.log(err.response)
            })

        return () => {
            dispatch({type: ADMIN_REDUCER, payload: {search: ''}})
        }
    }, [search, reload])

    const deleteAdmin = () => {
        deleteAdminApi(selectedRowKeys)
            .then(response => {
                dispatch({type: ADMIN_REDUCER, payload: {reload: !reload, selectedRowKeys: []}})
                dispatch({type: MODAL_REDUCER, payload: {isModalVisible: false}})
                alert('Admin berhasil di hapus!')
            })
            .catch(err => {
                console.log('Error deleteRoleApi', err.response)
            })
    }

    return (
        <>
            <HeaderPageComponent title={'Admin List'}/>
            <div style={{marginTop: 75}}>
                <Row justify={'space-between'} style={{marginBottom: 50, marginTop: 30}}>
                    <SearchComponent title={'admin'} onPressEnter={(e) => dispatch({
                        type: ADMIN_REDUCER,
                        payload: {search: e.target.value}
                    })}/>
                    {adminDetail?.capability_slugs?.includes('delete-admin') &&
                    <DeleteButtonComponent dataLength={selectedRowKeys.length} deleteContent={'admin'}/>}
                </Row>
                <AdminListComponent/>
            </div>
            <StandardModal onOkHandler={() => deleteAdmin()}/>
        </>
    )
}

export default AdminListPage
