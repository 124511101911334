import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import AdminForm from "./admin.form";

const CreateAdminPage = () => {
    return (
        <>
            <HeaderPageComponent title={'Create Admin'}/>
            <AdminForm slug={'create'}/>
        </>
    )
}

export default CreateAdminPage
