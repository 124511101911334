import React from "react";
import {Colors} from "../ui-util/colors";
import {Button, Row} from "antd";
import {ReactComponent as ExportIcon} from "../images/icon/icon-export.svg";

const ExportButtonComponent = (props) => {
    const {dataLength, allData} = props
    return (
        <Button size={'large'}
                disabled={dataLength === 0}
                style={{
                    borderRadius: 10,
                    backgroundColor: dataLength > 0 ? Colors.armyGray : Colors.darkGrey,
                    width: 130
                }}>
            <Row justify={'center'} style={{alignItems: 'center'}}>
                <ExportIcon/>
                <div style={{marginLeft: 7, fontSize: 14, color: 'white'}}>{allData ? 'Export All' : 'Export'}</div>
            </Row>
        </Button>
    )
}

export default ExportButtonComponent
