import React, {useState} from "react";
import {Table, List, Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ROLE_REDUCER} from "../../reducers/role.reducer";
import {Colors} from "../../ui-util/colors";

const RoleListComponent = () => {
    const dispatch = useDispatch();
    const {roleList, selectedRowKeys, loading} = useSelector(state => state.roleReducer)
    const [columns] = useState([
        {key: 'name', dataIndex: 'name', title: 'Name'},
        {
            key: 'capability', dataIndex: 'capabilities', title: 'Capabilities',
            render: capabilities => <List grid={{column: 3}} dataSource={capabilities} renderItem={(item) => (
                <div style={{color: Colors.armyGray}}>{item.name}</div>
            )}/>
        }, {
            key: 'action', title: 'Action',
            render: (record) => (
                <Button onClick={() => {
                    window.location.href = `/admin/role/update/${record.id}`
                }} style={{
                    borderWidth: 0,
                    color: Colors.armyGray,
                    backgroundColor: 'transparent'
                }}>Edit</Button>
            )
        }
    ])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            dispatch({type: ROLE_REDUCER, payload: {selectedRowKeys}});
        }
    }
    return (
        <Table
            columns={columns}
            dataSource={roleList}
            rowKey={'id'}
            rowSelection={rowSelection}
            loading={loading}
        />
    )
}

export default RoleListComponent
