import React from 'react'
import {Modal} from "antd";
import EditPointForm from "./edit-point-form";

const EditPointModal = ({isModalVisible, onClose, pointDetail}) => {
    return (
        <Modal
            title={'Edit Point'}
            visible={isModalVisible}
            onCancel={onClose}
            footer={null}
        >
            <EditPointForm pointDetail={pointDetail} onClose={onClose}/>
        </Modal>
    )
}

export default EditPointModal
