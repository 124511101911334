import React from "react";
import {Modal} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../reducers/modal.reducer";

const StandardModal = (props) => {
    const dispatch = useDispatch();
    const {isModalVisible, modalContent,modalTitle} = useSelector(state => state.modalReducer)
    const {onOkHandler} = props
    return (
        <Modal
            title={modalTitle}
            visible={isModalVisible}
            onOk={() => onOkHandler()}
            onCancel={() => dispatch({type: MODAL_REDUCER, payload: {isModalVisible: false}})}>
            <p>{modalContent}</p>
        </Modal>
    )
}

export default StandardModal
