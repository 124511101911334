import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import AdminForm from "../create-admin/admin.form";

const UpdateAdminPage = () => {
    return (
        <>
            <HeaderPageComponent title={'Admin List'} subTitle={'Update Admin'}/>
            <AdminForm slug={'edit'}/>
        </>
    )
}

export default UpdateAdminPage
