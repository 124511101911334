import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import CapabilityForm from "./capability.form";

const CreateCapabilityPage = () => {

    return (
        <>
            <HeaderPageComponent title={'Create Capability'}/>
            <div style={{marginTop: 75}}>
                <CapabilityForm slug={'create'}/>
            </div>
        </>
    )
}

export default CreateCapabilityPage
