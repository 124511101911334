import React, {useEffect} from "react";
import HeaderPageComponent from "../../components/header-page.component";
import RoleListComponent from "./role-list.component";
import {deleteRoleApi, getRoleListApi} from "../../api/role.api";
import {useDispatch, useSelector} from "react-redux";
import {ROLE_REDUCER} from "../../reducers/role.reducer";
import DeleteButtonComponent from "../../components/delete-button.component";
import {Row} from "antd";
import StandardModal from "../../components/standard.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const RoleListPage = () => {
    const dispatch = useDispatch();
    const {selectedRowKeys, reload} = useSelector(state => state.roleReducer)
    const {adminDetail} = useSelector(state => state.adminReducer)

    useEffect(() => {
        getRoleListApi()
            .then(response => {
                dispatch({type: ROLE_REDUCER, payload: {roleList: response}});
            })
            .catch(err => {
                console.log('Error getRoleListApi', err.response)
            })
    }, [reload])

    const deleteRole = () => {
        deleteRoleApi(selectedRowKeys)
            .then(response => {
                dispatch({type: ROLE_REDUCER, payload: {reload: !reload, selectedRowKeys: []}})
                dispatch({type: MODAL_REDUCER, payload: {isModalVisible: false}})
                alert('Role berhasil di hapus!')
            })
            .catch(err => {
                console.log('Error deleteCategoryApi', err.response)
            })
    }

    return (
        <>
            <HeaderPageComponent title={'Role List'}/>
            <div style={{marginTop: 75}}>
                {
                    adminDetail?.capability_slugs?.includes('delete-role') &&
                    <Row justify={'end'} style={{marginTop: 30}}>
                        <DeleteButtonComponent deleteContent={'role'} dataLength={selectedRowKeys.length}/>
                    </Row>
                }
                <RoleListComponent/>
            </div>
            <StandardModal onOkHandler={() => deleteRole()}/>
        </>
    )
}

export default RoleListPage
