import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, List, Checkbox, Typography, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import {getCategoryListWithCapabilitiesApi} from "../../api/category.api";
import {createRoleApi, getRoleDetailApi, updateRoleApi} from "../../api/role.api";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const {Text} = Typography;
const CheckboxGroup = Checkbox.Group;

const RoleForm = (props) => {
    const formRef = useRef({});
    const {id} = useParams();
    const {adminDetail} = useSelector(state => state.adminReducer)
    const {slug} = props
    const [categoryWithCapabilities, setCategoryWithCapabilities] = useState([])
    const [roleForm] = useState([
        {
            type: 'input',
            key: 'name',
            label: 'Role Name',
            placeholder: 'Sales Manager',
            message: 'Nama role wajib diisi',
        }, {
            type: 'checkbox',
            key: 'capability_ids',
            label: '',
            placeholder: 'Create product',
            message: 'Capability wajib diisi',
        },
    ])

    useEffect(() => {
        getCategoryListWithCapabilitiesApi()
            .then(response => {
                setCategoryWithCapabilities(response)
            })
            .catch(err => {
                console.log(err.response)
            })
    }, [])

    useEffect(() => {
        if (slug === 'edit') {
            getRoleDetailApi(id)
                .then(response => {
                    formRef.current.setFieldsValue({name: response.name, capability_ids: response.capability_ids})
                })
                .catch(err => {
                    console.log(err.response)
                })
        }
    }, [])

    const onFinish = (values) => {
        if (slug === 'create') {
            createRole(values)
        } else {
            updateRole(values)
        }
    }

    const createRole = (values) => {
        createRoleApi(values)
            .then(response => {
                alert('Role berhasil di buat!')
                window.location.href = '/admin/role-list'
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const updateRole = (values) => {
        updateRoleApi(id, values)
            .then(response => {
                alert('Role berhasil di ubah!')
                window.location.href = '/admin/role-list'
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    return (
        <Form
            ref={formRef}
            layout="vertical"
            style={{marginTop: 75}}
            onFinish={onFinish}
        >
            <List
                dataSource={roleForm}
                renderItem={(item) => {
                    switch (item.type) {
                        case 'checkbox':
                            return (
                                <Form.Item
                                    name={item.key}
                                    label={<div
                                        style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                                >
                                    <CheckboxGroup style={{width: '100%'}}>
                                        <Text style={{
                                            fontFamily: 'Belleza-Regular',
                                            fontSize: 32,
                                            color: Colors.armyGray
                                        }}>Capabilities</Text>
                                        <div style={{height: 1, backgroundColor: Colors.lightgray}}/>
                                        <List
                                            grid={{column: 5}}
                                            style={{marginTop: 30}}
                                            dataSource={categoryWithCapabilities}
                                            renderItem={(category) => (
                                                <>
                                                    <Text style={{
                                                        fontFamily: 'Belleza-Regular',
                                                        fontSize: 24,
                                                        color: Colors.armyGray
                                                    }}>{category.name}</Text>
                                                    {
                                                        category.capabilities.length > 0 &&
                                                        <List
                                                            style={{marginTop: 30}}
                                                            dataSource={category.capabilities}
                                                            renderItem={(capabilities) => (
                                                                <Row
                                                                    align={'center'}
                                                                    style={{
                                                                        width: 200,
                                                                        backgroundColor: 'white',
                                                                        height: 40,
                                                                        alignItems: 'center',
                                                                        marginBottom: 20,
                                                                        borderRadius: 12
                                                                    }}>
                                                                    <Checkbox
                                                                        style={{color: Colors.armyGray}}
                                                                        value={capabilities.id}>{capabilities.name}</Checkbox>
                                                                    <br/>
                                                                </Row>
                                                            )}
                                                        />}
                                                </>
                                            )}
                                        />
                                    </CheckboxGroup>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    name={item.key}
                                    label={<div
                                        style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                                    rules={[{required: true, message: item.message}]}
                                >
                                    <Input
                                        style={{
                                            width: 400,
                                            height: 40,
                                            borderRadius: 12,
                                            color: Colors.armyGray
                                        }} placeholder={item.placeholder}/>
                                </Form.Item>
                            )
                    }
                }}
            />
            {
                ((adminDetail?.capability_slugs?.includes('create-role') &&
                    slug === 'create') ||
                    (adminDetail?.capability_slugs?.includes('update-role') &&
                        slug === 'edit')) &&
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{
                        height: 40,
                        width: 150,
                        backgroundColor: Colors.armyGray,
                        borderRadius: 12,
                        borderWidth: 0,
                        marginTop: 10
                    }}>
                        { slug === 'edit' ? 'Save' :'Create Role'}
                    </Button>
                </Form.Item>}
        </Form>
    )
}

export default RoleForm
