import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import CapabilityForm from "../create-capability/capability.form";

const UpdateCapabilityPage = () => {
    return (
        <>
            <HeaderPageComponent title={'Capability List'} subTitle={'Edit Capability'}/>
            <CapabilityForm slug={'edit'}/>
        </>
    )
}

export default UpdateCapabilityPage
