import React, {useEffect} from "react";
import HeaderPageComponent from "../../components/header-page.component";
import {categoryListApi, deleteCategoryApi} from "../../api/category.api";
import {useDispatch, useSelector} from "react-redux";
import {CATEGORY_REDUCER} from "../../reducers/category.reducer";
import CategoryListComponent from "./category-list.component";
import StandardModal from "../../components/standard.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const CategoryListPage = () => {
    const dispatch = useDispatch();
    const {selectedRowKeys, reload} = useSelector(state => state.categoryReducer)

    useEffect(() => {
        dispatch({type: CATEGORY_REDUCER, payload: {loading: true}})
        categoryListApi()
            .then(response => {
                dispatch({type: CATEGORY_REDUCER, payload: {categoryList: response, loading: false}})
            })
            .catch(err => {
                console.log(err)
                dispatch({type: CATEGORY_REDUCER, payload: {loading: false}})
            })
    }, [reload])

    const deleteCategory = () => {
        deleteCategoryApi(selectedRowKeys)
            .then(response => {
                dispatch({type: CATEGORY_REDUCER, payload: {reload: !reload, selectedRowKeys: []}})
                dispatch({type: MODAL_REDUCER, payload: {isModalVisible: false}})
                alert('Category berhasil di hapus!')
            })
            .catch(err => {
                console.log('Error deleteCategoryApi', err.response)
            })
    }

    return (
        <>
            <HeaderPageComponent title={'Category List'}/>
            <div style={{marginTop: 75}}>
                <CategoryListComponent/>
                <StandardModal onOkHandler={() => deleteCategory()}/>
            </div>
        </>
    )
}

export default CategoryListPage
