import React from "react";
import {Button, Card, Form, Image, Input, Row} from "antd";
import {Link} from "react-router-dom";
import logoAvoskinPointEngine from "../../images/logo-avoskin-point-engine/point_engine_logo.webp";
import {Colors} from "../../ui-util/colors";
import {forgotPasswordApi} from "../../api/auth.api";

const ForgotPasswordPage = () => {

    const onFinish = (values) => {
        forgotPasswordApi(values)
            .then(response => {
                alert('Forgot password berhasil!. Silahkan cek email anda!')
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            <Row justify={'center'}>
                <Image src={logoAvoskinPointEngine} preview={false}/>
            </Row>
            <div style={{justifyContent: 'center', display: 'flex', marginTop: 30}}>
                <Card style={{width: 577, height: 695, alignItems: 'center'}}>
                    <Form onFinish={onFinish} labelCol={{span: 5}} style={{width: 500, padding: 30}}>
                        <div style={{
                            fontSize: 40,
                            fontFamily: 'Belleza-Regular',
                            color: Colors.armyGray,
                            textAlign: 'center',
                            marginTop: 30,
                            marginBottom: 50
                        }}>Forgot Password
                        </div>
                        <div style={{textAlign: 'center', marginBottom: 10, fontFamily: 'NunitoSans-SemiBold'}}>Email
                        </div>
                        <Form.Item
                            name="email"
                            rules={[{required: true, message: 'Please input your email!'}]}
                        >
                            <Input style={{height: 40, borderRadius: 20}}/>
                        </Form.Item>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Link to={'/'}>Login</Link>
                        </div>
                        <Form.Item wrapperCol={{offset: 7, span: 5}}>
                            <Button type="primary" htmlType="submit"
                                    style={{
                                        width: 200,
                                        height: 40,
                                        backgroundColor: Colors.armyGray,
                                        borderWidth: 0,
                                        borderRadius: 20,
                                        marginTop: 30
                                    }}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default ForgotPasswordPage
