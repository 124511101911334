import React, {useState} from "react";
import {Card, Row, Table} from "antd";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import {ReactComponent as PointGreenLogo} from "../../images/icon/icon-point-green.svg";
import {ReactComponent as PointRedLogo} from "../../images/icon/icon-point-red.svg";
import {useDispatch, useSelector} from "react-redux";
import {getDateCustomFormat} from "../../ui-util/general-variable";
import {USER_REDUCER} from "../../reducers/user.reducer";

const PointHistoryComponent = () => {
    const dispatch = useDispatch();
    const {pointList, pointListTotalData, pointListPage, pointListLimit} = useSelector(state => state.userReducer)
    const [columns] = useState([
        {
            key: 'order_id',
            render: (record) => <div style={{color: Colors.armyGray}}>{!!record.order_id ? record.order_id : record.action_type}</div>
        }, {
            key: 'point_cost', dataIndex: 'point_cost',
            render: (point) =>
                <Row style={{color: Colors.darkGrey, alignItems: 'center'}}>
                    {point > 0 ? <PointGreenLogo/> : <PointRedLogo/>}
                    <div style={{marginLeft: 10, color: point > 0 ? Colors.armyGray : Colors.indianRed}}>{point} Points</div>
                </Row>
        }, {
            key: 'action_name', dataIndex: 'action_name',
            render: (description) => <div style={{color: Colors.darkGrey}}>{description}</div>
        }, {
            key: 'created_at', dataIndex: 'created_at',
            render: (created_at) => <div
                style={{color: Colors.darkGrey}}>{getDateCustomFormat(created_at, 'DD MMM yyyy hh:mm')}</div>
        },
    ])

    return (
        <Card style={{width: 950, marginTop: 30, borderRadius: 12}}>
            <div style={Styles.fontArmyGrayBold}>Riwayat Poin</div>
            <Table
                style={{marginTop: 20}}
                showHeader={false}
                columns={columns}
                dataSource={pointList}
                rowKey={'id'}
                pagination={{
                    total: pointListTotalData,
                    current: pointListPage,
                    onChange: (current) => dispatch({type: USER_REDUCER, payload: {pointListPage: current}}),
                    showSizeChanger: false,
                    pageSize: pointListLimit
                }}
            />
        </Card>
    )
}

export default PointHistoryComponent
