import axios from 'axios';
import {baseUrl} from '../ui-util/general-variable';
import {DEFAULT_API_HEADER} from "./api.config";

const userSignInApi = (requestBody) => {
	const params = new URLSearchParams();
	params.append('username', requestBody.username);
	params.append('password', requestBody.password);

	return new Promise((resolve, reject) => {
		axios
			.post(`${baseUrl()}/v1/login/`, params, {headers: DEFAULT_API_HEADER})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
};

const forgotPasswordApi = (requestBody) => {

	return new Promise((resolve, reject) => {
		axios
			.post(`${baseUrl()}/v1/admin/forgot-password`, requestBody, {headers: DEFAULT_API_HEADER})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
};

export { userSignInApi, forgotPasswordApi };
