import React from "react";
import {Button, Row} from "antd";
import {Colors} from "../ui-util/colors";
import {ReactComponent as DeleteIcon} from "../images/icon/icon-delete.svg";
import {MODAL_REDUCER} from "../reducers/modal.reducer";
import {useDispatch} from "react-redux";

const DeleteButtonComponent = (props) => {
    const dispatch = useDispatch();
    const {dataLength, deleteContent} = props
    return (
        <Button
            onClick={() => dispatch({
                type: MODAL_REDUCER,
                payload: {
                    isModalVisible: true,
                    modalContent: `Apakah anda akan menghapus ${deleteContent} ini?`,
                    modalTitle: `Delete ${deleteContent}`
                }
            })}
            size={'large'}
            disabled={dataLength === 0}
            style={{
                borderRadius: 10,
                backgroundColor: dataLength > 0 ? Colors.indianRed : Colors.darkGrey,
                width: 100
            }}>
            <Row justify={'center'} style={{alignItems: 'center'}}>
                <DeleteIcon/>
                <div style={{marginLeft: 7, fontSize: 14, color: 'white'}}>Delete</div>
            </Row>
        </Button>
    )
}

export default DeleteButtonComponent
