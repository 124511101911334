import React, {useState} from "react";
import {Button, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {CAPABILITY_REDUCER} from "../../reducers/capability.reducer";
import {Colors} from "../../ui-util/colors";

const CapabilityListComponent = () => {
    const dispatch = useDispatch();
    const {capabilityList, selectedRowKeys, loading} = useSelector(state => state.capabilityReducer)

    const [columns] = useState([
        {
            key: 'name', dataIndex: 'name', title: 'Name'
        }, {
            key: 'category', dataIndex: 'category', title: 'Category',
            render: category => category.name
        }, {
            key: 'slug', dataIndex: 'slug', title: 'Slug'
        }, {
            key: 'action', title: 'Action',
            render: (record) => (
                <Button onClick={() => {
                    window.location.href = `/admin/capability/update/${record.id}`
                }} style={{
                    borderWidth: 0,
                    color: Colors.armyGray,
                    backgroundColor: 'transparent'
                }}>Edit</Button>
            )
        }
    ])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            dispatch({type: CAPABILITY_REDUCER, payload: {selectedRowKeys}});
        }
    }

    return (
        <Table
            columns={columns}
            dataSource={capabilityList}
            rowKey={'id'}
            rowSelection={rowSelection}
            loading={loading}
        />
    )
}

export default CapabilityListComponent
