import React from "react";
import {Colors} from "../ui-util/colors";
import {Button, Row, Select} from "antd";
import {ReactComponent as PageSizeIcon} from "../images/icon/icon-page-size.svg";

const {Option} = Select;

const PageSizeComponent = (props) => {
    const {onSelect} = props
    return (
        <Button size={'large'} style={{
            borderRadius: 10,
            borderWidth: 1,
            borderColor: Colors.armyGray,
            backgroundColor: Colors.softArmy,
            width: 200
        }}>
            <Row justify={'center'} style={{alignItems: 'center'}}>
                <PageSizeIcon/>
                <Select style={{color: Colors.armyGray, width: 130}} bordered={false}
                        defaultValue={'Show 10 Items'} showArrow={false}
                        onSelect={onSelect}
                >
                    {
                        [10, 20, 50, 100].map((item, index) =>
                            <Option value={item} key={index.toString()}>Show {item} items</Option>
                        )
                    }
                </Select>
            </Row>
        </Button>
    )
}

export default PageSizeComponent
