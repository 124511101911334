const INITIAL_STATE = {
    importType: 'create',
    excelJson: [],
    fileList: []
}

export const IMPORT_REDUCER = 'IMPORT_REDUCER';

export const importReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IMPORT_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
