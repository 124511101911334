import React, {useState} from "react";
import {Button, Input, Modal, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../reducers/modal.reducer";
import {Colors} from "../ui-util/colors";
import {Styles} from "../ui-util/styles";
import {ReactComponent as PointGreenLogo} from "../images/icon/icon-point-green.svg";
import {ReactComponent as PointRedLogo} from "../images/icon/icon-point-red.svg";
import {updateUserPointApi} from "../api/user.api";
import {useParams} from "react-router-dom";

const PointModal = () => {
    const {id} = useParams();
    const {point: userPoint} = useSelector(state => state.userReducer.userDetail)
    const [point, setPoint] = useState('')
    const dispatch = useDispatch();
    const {pointModalVisible, pointModalType} = useSelector(state => state.modalReducer)
    const [errorMessage, setErrorMessage] = useState('')
    const savePointHandler = () => {
        if(pointModalType === 'plus' && point < 0){
            setErrorMessage('Nominal tidak boleh minus')
        } else if(pointModalType === 'minus' && point > 0){
            setErrorMessage('Nominal harus minus')
        } else if(pointModalType === 'minus' && ((Number(userPoint) + Number(point))) < 0){
            setErrorMessage('Poin yang di inputkan lebih besar dari poin user')
        } else {
            setErrorMessage('')
            let params = {
                point,
                user_id: Number(id)
            }
            updateUserPointApi(params, id)
                .then(response => {
                    dispatch({type: MODAL_REDUCER, payload: {pointModalVisible: false}})
                    window.location.reload()
                })
                .catch(err => console.log(err.response))
        }
    }

    return (
        <Modal
            visible={pointModalVisible}
            width={350}
            bodyStyle={{height: 220}}
            onCancel={() => dispatch({type: MODAL_REDUCER, payload: {pointModalVisible: false}})}
            footer={null}
            cancelText={false}
        >
            <div style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 16
            }}>{pointModalType === 'plus' ? 'Tambah' : 'Kurangi'} point
            </div>
            <Input prefix={pointModalType === 'plus' ? <PointGreenLogo/> : <PointRedLogo/>}
                   style={{borderRadius: 12, marginTop: 20, height: 40}}
                   onChange={(e) => setPoint(e.target.value)}
                   placeholder={pointModalType === 'plus' ? '2000' : '-2000'}
                   value={point}
            />
            <div style={{color: 'red', textAlign: 'center', marginTop: 5, fontSize: 12}}>{errorMessage}</div>
            <Row justify={'space-between'} style={{marginTop: 30}}>
                <Button
                    onClick={() => {
                        setErrorMessage('')
                        setPoint('')
                        dispatch({type: MODAL_REDUCER, payload: {pointModalVisible: false}})
                    }}
                    style={{
                        borderColor: Colors.lightgray,
                        backgroundColor: Colors.snow,
                        width: 120,
                        height: 40,
                        color: Colors.armyGray,
                        fontWeight: 'bold',
                        borderRadius: 12
                    }}>Batal</Button>
                <Button
                    onClick={savePointHandler}
                    style={{...Styles.buttonWithRadius, height: 40, fontWeight: 'bold'}}>Simpan</Button>
            </Row>
        </Modal>
    )
}

export default PointModal
