import React from "react";
import ExportButtonComponent from "./export-button.component";
import ReactExport from "react-export-excel";
import {convertPhoneNumber, getDateCustomFormat} from "../ui-util/general-variable";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportUser = (props) => {
    const {data} = props
    return (
        <ExcelFile element={<ExportButtonComponent dataLength={data.length}/>} filename={'User'}>
            <ExcelSheet data={data} name="Product List">
                <ExcelColumn label="Id" value={(col) => col.User.id}/>
                <ExcelColumn label="First Name" value={(col) => col.User.first_name}/>
                <ExcelColumn label="Last Name" value={(col) => col.User.last_name}/>
                <ExcelColumn label="Gender" value={(col) => col.User.gender}/>
                <ExcelColumn label="Email" value={(col) => col.User.email}/>
                <ExcelColumn label="Phone Number" value={(col) => convertPhoneNumber(col.User.phone_number)}/>
                <ExcelColumn label="Point" value="point"/>
                <ExcelColumn label="Created At" value={(col) => !!col.User.created_at ? getDateCustomFormat(col.User.created_at, 'DD MMM yyyy HH:mm') : '' }/>
            </ExcelSheet>

        </ExcelFile>
    )
}

export default ExportUser
