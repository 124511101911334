import React from "react";
import {Row} from "antd";
import SearchComponent from "../../components/search.component";
import {Styles} from "../../ui-util/styles";
import PageSizeComponent from "../../components/page-size.component";
import {useDispatch, useSelector} from "react-redux";
import {USER_REDUCER} from "../../reducers/user.reducer";
import ExportUser from "../../components/export-user.component";
import DeleteButtonComponent from "../../components/delete-button.component";
import StandardModal from "../../components/standard.modal";
import SortComponent from "../../components/sort.component";
import {deleteUserApi} from "../../api/user.api";
import ExportAllButtonComponent from "../../components/export-all-button.component";

const UserFilterComponent = () => {
    const dispatch = useDispatch();
    const {selectedRows, isUserListWithPoint, selectedRowKeys} = useSelector(state => state.userReducer)
    const {adminDetail} = useSelector(state => state.adminReducer)

    const deleteUserHandler = () => {
        deleteUserApi(selectedRowKeys)
            .then(response => {
                alert('User berhasil dihapus !')
                window.location.reload()
            })
            .catch(err => console.log(err.response))
    }

    return (
        <Row style={Styles.rowFilter} justify={'space-between'}>
            <SearchComponent title={'user'}
                             onPressEnter={(e) => dispatch({type: USER_REDUCER, payload: {search: e.target.value}})}/>

            <SortComponent page={isUserListWithPoint ? 'user' : ''}
                           onSelect={(item) => dispatch({type: USER_REDUCER, payload: {sort_by: item}})}/>

            <PageSizeComponent onSelect={(item) => dispatch({type: USER_REDUCER, payload: {limit: item, currentPage: 1}})}/>

            <ExportUser data={selectedRows}/>

            <ExportAllButtonComponent />

            {
                adminDetail?.capability_slugs?.includes('delete-user') &&
                <DeleteButtonComponent deleteContent={"user"} dataLength={selectedRows.length}/>}
            <StandardModal onOkHandler={deleteUserHandler}/>
        </Row>
    )
}

export default UserFilterComponent
