import axios from "axios";
import {baseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER_WITH_TOKEN} from "./api.config";

const getCapabilityListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/capability/`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const createCapabilityApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/capability/create`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
};

const updateCapabilityApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/capability/update/${id}`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getCapabilityDetailApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/v1/capability/${id}`,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const deleteCapabilityApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/v1/capability/delete/bulk`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN
            }
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export {
    getCapabilityListApi,
    createCapabilityApi,
    updateCapabilityApi,
    getCapabilityDetailApi,
    deleteCapabilityApi
}
