const INITIAL_STATE = {
    selectedRowKeys: [],
    search: '',
    reload: false,
    imageUrl: '',
    imageErrorMessage: false,
    loading: false,
    adminList: [],
    adminDetail: {}
}

export const ADMIN_REDUCER = 'ADMIN_REDUCER';

export const adminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
