import React from "react";
import {Colors} from "../ui-util/colors";
import {Button, Row} from "antd";
import {ReactComponent as ExportIcon} from "../images/icon/icon-export.svg";
import {getUserFileExportApi} from "../api/user.api";

const ExportAllButtonComponent = () => {

    const getUserFileExport = () => {
        getUserFileExportApi()
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'user.xlsx');
                document.body.appendChild(link);
                link.click();
            });
    }

    return (
        <Button size={'large'}
                style={{
                    borderRadius: 10,
                    backgroundColor: Colors.armyGray,
                    width: 130
                }} onClick={getUserFileExport}>
            <Row justify={'center'} style={{alignItems: 'center'}}>
                <ExportIcon/>
                <div style={{marginLeft: 7, fontSize: 14, color: 'white'}}>Export All</div>
            </Row>
        </Button>
    )
}

export default ExportAllButtonComponent
