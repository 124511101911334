import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import PointSettingList from "./point-setting-list";

const PointSettingPage = () => {
    return (
        <>
            <HeaderPageComponent title={'Admin Setting'} subTitle={'Point'}/>
            <div style={{marginTop: 75, backgroundColor: 'white', padding: 30}}>
                <PointSettingList/>
            </div>
        </>
    )
}

export default PointSettingPage
