import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Image, Input, Row, Upload, Spin} from "antd";
import addImageIcon from "../../images/add-image/add-image@3x.png";
import {Colors} from "../../ui-util/colors";
import {createProductApi, getProductDetailApi, updateProductApi, uploadImageProductApi} from "../../api/product.api";
import {useDispatch, useSelector} from "react-redux";
import {PRODUCT_REDUCER} from "../../reducers/product.reducer";
import {useParams} from "react-router-dom";
import {LoadingOutlined} from '@ant-design/icons';

const ProductForm = (props) => {
    const formRef = useRef({});
    const dispatch = useDispatch();
    const {sku} = useParams();
    const {slug} = props
    const [productDetailImage, setProductDetailImage] = useState('')
    const {adminDetail} = useSelector(state => state.adminReducer)
    const {imageUrl, imageErrorMessage} = useSelector(state => state.productReducer)
    const [fileImageList, setFileImageList] = useState([])
    const [loading, setLoading] = useState(false)
    const [productFormList] = useState([
        {
            key: 'product_name',
            label: 'Product Name',
            placeholder: 'Your Skin Bae Alpha Arbutin 3% + Grapeseed',
            message: 'Nama produk wajib diisi',
        }, {
            key: 'sku',
            label: 'SKU',
            placeholder: 'Masukkan nomor sku',
            message: 'SKU produk wajib diisi',
        }, {
            key: 'value',
            label: 'Jumlah Poin',
            placeholder: 'Masukkan jumlah poin',
            message: 'Poin produk wajib diisi',
        }
    ])


    useEffect(() => {
        if (!!sku) {
            getProductDetailApi(sku)
                .then(response => {
                    formRef.current.setFieldsValue(response)
                    setProductDetailImage(response.image)
                })
                .catch(err => console.log('Error getProductDetailApi', err))
        }

        return () => {
            dispatch({type: PRODUCT_REDUCER, payload: {imageUrl: ''}})
        }
    }, [formRef.current])

    const beforeUploadImage = (fileImage) => {
        if (fileImage.size > 1000000) {
            alert('Ukuran gambar terlalu besar. Maks 1 MB')
        } else {
            setFileImageList([fileImage])
            setLoading(true)
            dispatch({type: PRODUCT_REDUCER, payload: {imageErrorMessage: false}})
        }
    }

    const uploadImage = () => {
        const form = new FormData();
        form.append('image', fileImageList[0])
        uploadImageProductApi(form)
            .then(response => {
                fileImageList[0].url = response.image_url
                setFileImageList(fileImageList)
                setLoading(false)
                dispatch({type: PRODUCT_REDUCER, payload: {imageUrl: response.image_url}})
            })
            .catch(err => {
                setLoading(false)
                console.log('Err uploadImageProductApi', err)
            })
    }

    const onFinish = (values) => {
        values.value = Number(values.value)
        if(slug === 'create'){
            createProduct(values)
        } else {
            updateProduct(values)
        }
    }

    const createProduct = (values) => {
        if(!!imageUrl){
            values.image = imageUrl
            createProductApi(values)
                .then(result => {
                    alert('Produk berhasil di buat!')
                    window.location.href='/product/list'
                })
                .catch(err => {
                    if(err.message.includes('400')){
                        alert('SKU sudah pernah digunakan !!')
                    } else {
                        console.log('Error createProductApi', err)
                    }
                })
        } else {
            dispatch({type: PRODUCT_REDUCER, payload: {imageErrorMessage: true}})
        }
    }

    const updateProduct = (values) => {
        values.image = !!imageUrl ? imageUrl : values.image
        updateProductApi(sku,values)
            .then(result => {
                alert('Produk berhasil di update!')
                window.location.href = '/product/list'
            })
            .catch(err => console.log('Error updateProductApi', err))
    }

    return (
        <Row style={{marginTop: 50, marginBottom: -60}}>
            <div style={{marginTop: 10}}>
                <Upload
                    customRequest={uploadImage}
                    beforeUpload={beforeUploadImage}
                    fileList={fileImageList}
                    showUploadList={false}
                >
                    {
                        !!imageUrl ?
                            <Image style={{width: 236, height: 236}} src={imageUrl} preview={false}/> :
                            loading ? <Spin style={{width: 236, marginTop: 115}}
                                            indicator={<LoadingOutlined
                                                style={{color: Colors.armyGray, fontSize: 30}}/>}/> :
                                <Image style={{width: 236, height: 236}}
                                       src={!!productDetailImage ? productDetailImage : addImageIcon} preview={false}/>
                    }
                </Upload>
                {
                    imageErrorMessage &&
                    <div style={{textAlign: 'center', fontSize: 12, color: 'red'}}>Foto produk wajib
                        diisi</div>}
            </div>
            <Form
                ref={formRef}
                layout="vertical"
                style={{marginLeft: 50}}
                onFinish={onFinish}
            >
                {
                    productFormList.map((item) => (
                        <Form.Item
                            key={item.key}
                            name={item.key}
                            label={<div style={{color: Colors.darkGreen, fontWeight: 'bold'}}>{item.label}</div>}
                            rules={[{required: true, message: item.message}]}
                        >
                            <Input
                                style={{
                                    width: 400,
                                    height: 40,
                                    borderRadius: 12
                                }} placeholder={item.placeholder}/>
                        </Form.Item>
                    ))
                }
                {
                    ((adminDetail?.capability_slugs?.includes('create-category') &&
                        slug === 'create') ||
                        (adminDetail?.capability_slugs?.includes('update-category') &&
                            slug === 'edit')) &&
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{
                            height: 40,
                            width: 150,
                            backgroundColor: Colors.armyGray,
                            borderRadius: 12,
                            borderWidth: 0,
                            marginTop: 10
                        }}>
                            {!!sku ? 'Save' : 'Create Product'}
                        </Button>
                    </Form.Item>
                }
            </Form>
        </Row>
    )
}

export default ProductForm
