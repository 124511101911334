import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import UserForm from "./user.form";

const CreateUserPage = () => {
    return (
        <>
            <HeaderPageComponent title={'Create User'}/>
            <div style={{marginTop: 75}}>
                <UserForm slug={'create'}/>
            </div>
        </>
    )
}

export default CreateUserPage
