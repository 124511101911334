import React from "react";
import HeaderPageComponent from "../../components/header-page.component";
import UserForm from "../create-user/user.form";

const UpdateUserPage = () => {

    return (
        <>
            <HeaderPageComponent title={'User List'} subTitle={'Update User'}/>
            <div style={{marginTop: 75}}>
                <UserForm slug={'edit'}/>
            </div>
        </>
    )
}

export default UpdateUserPage
